import { GetterTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../../types';
import { ReferenceParent } from '@/shared/model/referenceParent';
import { ApplicationUserPermissions } from '@/shared/model/applicationUserPermissions';
import { CredentialContact, Credentials } from '@/shared/model/credentials';

export const getters: GetterTree<AuthState, RootState> = {
  /**
   * Answers whether the user is authenticated (an access token is provided).
   */
  // return a function so it is no cached
  ['token']: (state: AuthState) => () => state.token,
  /**
   * Answers the details of the current employee.
   */
  ['employee']: (state: AuthState) => state.account,
  isLoggedIn(state): boolean {
    return null != state.account && state.account.id! != '';
  },
  getIsFacebookLogin(state: AuthState): boolean | undefined {
    return state.isFacebookLogin;
  },
  getIsGoogleLogin(state: AuthState): boolean | undefined {
    return state.isGoogleLogin;
  },
  getUser(state: AuthState): Credentials | undefined | null {
    return state.account;
  },
  getUserId(state: AuthState): string | null {
    return state.account ? state.account.id! : null;
  },
  getAccountId(state: AuthState): string | null {
    return state.accountId ? state.accountId! : null;
  },
  getCompanyId(state: AuthState): string | null {
    return state.companyId ? state.companyId! : null;
  },
  getAttendeeId(state: AuthState): string | null {
    return state.account?.contacts[state.account?.selectedContactIndex].attendeeId ?? null;
  },
  getMembershipTypes(state: AuthState): ReferenceParent[] {
    return state.account?.contacts[state.account?.selectedContactIndex]?.membershipTypes ?? [];
  },
  getContacts(state: AuthState): CredentialContact[] | null {
    return state.account?.contacts ?? null;
  },
  getSelectedContactIndex(state: AuthState): number | null {
    return state.account?.selectedContactIndex ?? null;
  },
  getSelectedContact(state: AuthState): CredentialContact | null {
    return state.account?.contacts[state.account?.selectedContactIndex] ?? null;
  },
  getSelectedContactLogo(state: AuthState): string | null {
    return state.account?.contacts[state.account?.selectedContactIndex].logo ?? null;
  },
  hasMultipleContacts(state: AuthState): boolean {
    return state.account?.contacts ? state.account.contacts.length > 1 : false;
  },
  isManager(state: AuthState): boolean {
    return state.isManager;
  },
  isEmployee(state: AuthState): boolean {
    return state.isEmployee;
  },
  isAdmin(state: AuthState): boolean {
    return state.isAdmin;
  },
  isTrainer(state: AuthState): boolean {
    return state.isTrainer;
  },
  isAttendee(state: AuthState): boolean {
    return state.isAttendee;
  },
  isDepartmentLeader(state: AuthState): boolean {
    return state.isDepartmentLeader;
  },
  isEmailConfirmed(state: AuthState): boolean {
    return state.account ? state.account.emailConfirmed : false;
  },
  isTrialBeforeEnd(state: AuthState): boolean {
    return (
      state.trialDaysLeft != -1 &&
      state.trialDaysLeft <= 7
    );
  },
  isTrialExpired(state: AuthState): boolean {
    return state.trialDaysLeft == 0;
  },
  isTrialRunning(state: AuthState): boolean {
    return state.trialDaysLeft > 1;
  },
  daysLeftAfterCreation(state: AuthState): number {
    return state.daysLeftAfterCreation;
  },
  trialDaysLeft(state: AuthState): number {
    return state.trialDaysLeft;
  },
  isNeedMoreLicenses(state: AuthState): boolean {
    return state.totalLicences + 1 < state.totalActiveUsersInDb;
  },
  isResponsibleOrDeputy(state: AuthState): boolean {
    return state.isResponsibleOrDeputy;
  },
  getPermissions(state: AuthState): ApplicationUserPermissions {
    return state.permissions;
  }
};
