import { CONST } from "../utils/constants";
import DateUtils from "../utils/dateUtils";

export interface AttendeeCourseWaitingPosition {
  id: string, //Guid
  attendeeId: string, //Guid
  dateTimeId: string, //Guid
  position: number,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  birthDay?: string, //DateTime
  creatorId?: string, //Guid
  createdDate: string, //DateTime
  companyId: string, //Guid
}

export interface Value {
  value: AttendeeCourseWaitingPosition[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function toAPI(data: Partial<AttendeeCourseWaitingPosition>): any {
  return {
    Id: data?.id || undefined,
    AttendeeId: data?.attendeeId || undefined,
    DateTimeId: data?.dateTimeId || undefined,
    Position: data?.position || 0
  };
}

function parse(data?: Partial<AttendeeCourseWaitingPosition>): AttendeeCourseWaitingPosition {
  return {
    id: data?.id || CONST.emptyGuid,
    attendeeId: data?.attendeeId || CONST.emptyGuid,
    dateTimeId: data?.dateTimeId || CONST.emptyGuid,
    position: data?.position ?? 0,
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    phone: data?.phone || '',
    email: data?.email || '',
    birthDay: data?.birthDay || undefined,
    creatorId: data?.creatorId || undefined,
    createdDate: data?.createdDate || DateUtils.getTodayDateAsIsoString(),
    companyId: data?.companyId || CONST.emptyGuid,
  };
}

export default {
  parse,
  toAPI: toAPI,
};