<div
  :class="{'d-flex justify-center margin-location-mobile' : mobile, 'd-flex justify-center' : !mobile}"
>
  <v-select
    :items="selectboxEntries"
    :model-value="selectedContactId"
    item-title="name"
    item-value="id"
    hide-details
    variant="solo"
    flat
    class="max-width"
    @update:modelValue="selectboxEntryChanged"
  >
  <template v-slot:selection="{ item }">
    <tooltip-text
      v-if="item != undefined"
      :maxLength="24"
      :text="item.title"
      :class="{'location-entry-mobile text-h6' : mobile, 'location-entry-desktop text-h5' : !mobile}"
      class="font-weight-medium"
    >
    </tooltip-text>
  </template>
  <template v-slot:item="{ item, props }">
    <v-list-item v-if="item != undefined" v-bind="props">
      <template v-slot:title>
        <div
          :class="{'location-entry-mobile text-body-2' : mobile, 'location-entry-desktop text-body-1' : !mobile}"
          class="pa-2 font-weight-regular"
        >
          {{ item.title }}
        </div>
      </template>
    </v-list-item>
  </template>
  </v-select>
</div>