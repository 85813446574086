import { ActionTree } from 'vuex';
import { LocationOpeningHoursState } from './types';
import { RootState } from '../../types';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AxiosResponse } from 'axios';
import { defaultBackendLocationOpeningHours } from '@/shared/backend/locationOpeningHours';
import { LocationOpeningHours } from '@/shared/model/locationOpeningHours';

const logger = new Logger('actions.locationOpeningHours');
export const actions: ActionTree<LocationOpeningHoursState, RootState> = {
  getOpeningHoursByLocation({ commit }, locationId: string) {
    return defaultBackendLocationOpeningHours
      .getOpeningHoursByLocation(locationId)
      .then((response: AxiosResponse<LocationOpeningHours[]>) => {
        commit('setOpeningHours', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
  },
  resetOpeningHours({ commit }) {
    commit('setOpeningHours', []);
  },
  postMultipleOpeningHours(
    { commit },
    payload: { openingHours: LocationOpeningHours[], locationId: string }
  ) {
    return defaultBackendLocationOpeningHours
      .postMultipleOpeningHours(payload.locationId, payload.openingHours)
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  }
}