import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import dateTime, { DateTime, Value } from '../model/dateTime';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/constants';

const logger = new Logger('backend.DateTime');
export interface BackendDateTime {
  getDateTime(id: string): AxiosPromise<DateTime>;
  getDateTimes: (
    searchParams: SearchParams,
    courseIds?: string[],
    locationId?: string,
    departmentId?: string,
  ) => AxiosPromise<Value>;
  deleteDateTime(id: string): AxiosPromise;
  updateDateTime(DateTime: DateTime): AxiosPromise<any>;
  checkIfDateTimeIsBookedSeriesCourse(id: string): AxiosPromise<boolean>;
}

export const defaultBackendDateTime: BackendDateTime = {
  getDateTime(id: string): AxiosPromise<DateTime> {
    let url = `${URLS.dateTimeOdata}/${id}`;
    return instance.get<DateTime>(url);
  },
  getDateTimes(
    searchParams: SearchParams,
    courseIds?: string[],
    locationId?: string,
    departmentId?: string,
  ): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');
    let baseUrl = `${URLS.dateTimeOdata}`;

    if (courseIds != undefined && courseIds.length > 0) {
      const odfbCourses = ODataFilterBuilder('or');
      courseIds.forEach((x) => odfbCourses.eq(CONST.CourseId, x, false));
      odfb.and(odfbCourses);
    }

    if (locationId != undefined) {
      odfb.eq(CONST.LocationId, locationId, false);
    }

    if (departmentId != undefined) {
      baseUrl = `${URLS.dateTimeOdata}/GetInDepartment(departmentId=${departmentId})`;
    }

    const url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getDateTimes${url}`);
    return instance.get<Value>(url);
  },
  deleteDateTime(id: string): AxiosPromise {
    logger.debug('deleteDateTime');
    return instance.delete(`${URLS.dateTime}/${id}`);
  },
  updateDateTime(DateTime: DateTime): AxiosPromise<any> {
    logger.debug('updateDateTime');
    if (DateTime.id != '' && DateTime.id != undefined) {
      return instance.put<DateTime>(
        `${URLS.dateTime}`,
        dateTime.toAPI(DateTime)
      );
    } else {
      return instance.post<DateTime>(
        `${URLS.dateTime}`,
        dateTime.toAPI(DateTime)
      );
    }
  },
  checkIfDateTimeIsBookedSeriesCourse(id: string): AxiosPromise<boolean> {
    return instance.get<boolean>(
      `${URLS.dateTime}/CheckIfDateTimeIsBookedSeriesCourse/${id}`
    );
  }
};
