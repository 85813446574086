import { ActionTree } from 'vuex';
import { CourseState } from './types';
import { RootState } from '../../types';
import { defaultBackendCourse } from '@/shared/backend/course';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, Course } from '@/shared/model/course';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '../../../utils/constants';
import { isEmptyId } from '@/shared/utils/generalUtils';
import { createSearchParams } from '@/shared/model/searchParams';

const logger = new Logger('actions.courses');
export const actions: ActionTree<CourseState, RootState> = {
  getLogoByCourseId({ commit, dispatch }, id: string) {
    return defaultBackendCourse
      .getLogoByCourseId(id)
      .then((response: AxiosResponse<Course>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCoursesOdata(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      locationIds?: string[];
      departmentIds?: string[];
      companyId?: string;
      includeEvents?: boolean;
    }
  ) {
    commit('setCoursesIsLoading', true);
    const locationIds = payload?.locationIds ?? [];
    const departmentIds = payload?.departmentIds ?? [];
    const companyId = payload?.companyId || CONST.emptyGuid;
    const includeEvents = payload?.includeEvents ?? false;

    return defaultBackendCourse
      .getCoursesOdata(locationIds, departmentIds, companyId, includeEvents)
      .then((response: AxiosResponse) => {
        commit("setCoursesByOptions", response.data);
        commit("setCoursesIsLoading", false);
        return response.data;
      })
      .catch((e: any) => {
        commit("setCoursesIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  getCourses(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      locationId?: string;
      locationIds?: string[];
      departmentId?: string;
      noStore?: boolean;
      active?: boolean;
      includeEvents?: boolean;
      courseIds?: string[];
    }
  ) {
    commit('setCoursesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getCoursesSearchParams;
    let locationIds = payload?.locationIds ?? [];
    if (payload?.locationId != undefined) {
      locationIds.push(payload!.locationId!);
    }

    return defaultBackendCourse
      .getCourses(
        searchParams,
        locationIds,
        payload?.departmentId,
        payload?.active,
        payload?.includeEvents,
        payload?.courseIds,
      )
      .then((response: AxiosResponse<Value>) => {
        commit('setCoursesIsLoading', false);
        if (payload?.noStore) return response.data;

        commit('setCourses', response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit('setCoursesIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getCourse({ commit, dispatch }, id: string) {
    return defaultBackendCourse
      .getCourse(id)
      .then((response: AxiosResponse<Course>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateCourse({ commit, dispatch }, file: Course) {
    return defaultBackendCourse
      .updateCourse(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Course;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.course_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  async getCoursesWithLogo({ commit }, payload?: { companyId?: string }) {
    try {
      const courses = await defaultBackendCourse.getCoursesWithLogo(payload);
      return courses.data;
    } catch (e) {
      moduleUtils.error('error', commit, e, logger);
      return [];
    }
  },
  deleteCourse({ commit, dispatch }, id: string) {
    return defaultBackendCourse
      .deleteCourse(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Course;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  makeCopyOfTheCourse({ commit }, id: string) {
    if (isEmptyId(id)) return;
    return defaultBackendCourse
      .makeCopyOfTheCourse(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Course;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  async checkForNonCanceledFutureAppointments(
    { dispatch, rootState },
    id: string
  ): Promise<boolean> {
    if (isEmptyId(id)) return false;
    const payloadArgs = {
      searchParams: createSearchParams(1),
      courseId: id,
    };
    await dispatch(
      'appointmentModule/getNonCanceledFutureAppointments',
      payloadArgs,
      { root: true }
    );

    return (rootState as any).appointmentModule.appointments.items.length > 0;
  },

  checkIfCourseHasFutureBookings({}, id: string) {
    return defaultBackendCourse.checkIfCourseHasFutureBookings(id);
  }
};
