import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { DefaultBackendHelper } from '../utils/backendHelper';
import { isEmptyId } from '../utils/generalUtils';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/constants';
import attendeeCourseWaitingPositionFuncs, {
  AttendeeCourseWaitingPosition,
  Value,
} from '../model/attendeeCourseWaitingPosition';

const logger = new Logger('backend.AttendeeCourseWaitingPosition');
export interface BackendAttendeeCourseWaitingPosition {
  getAttendeeCourseWaitingPositions: (
    searchParams: SearchParams,
    dateTimeId?: string
  ) => AxiosPromise<Value>;
  deleteAttendeeCourseWaitingPosition(id: string): AxiosPromise;
  updateAttendeeCourseWaitingPosition(
    attendeeCourseWaitingPosition: AttendeeCourseWaitingPosition
  ): AxiosPromise<any>;
  downloadPdf(dateTimeId: string, lang: string): AxiosPromise<any>;
}

export const defaultBackendAttendeeCourseWaitingPosition: BackendAttendeeCourseWaitingPosition = {
  getAttendeeCourseWaitingPositions(
    searchParams: SearchParams,
    dateTimeId?: string
  ): AxiosPromise<Value> {
    const odfb = ODataFilterBuilder('and');

    if (dateTimeId != undefined) {
      odfb.eq(CONST.DateTimeId, dateTimeId, false);
    }

    const url = DefaultBackendHelper.makeUrl(
      URLS.attendeeCourseWaitingPositionOdata,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getAttendeeCourseWaitingPositions: ${url}`);
    return instance.get<Value>(url);
  },
  deleteAttendeeCourseWaitingPosition(id: string): AxiosPromise {
    logger.debug('deleteAttendeeCourseWaitingPosition');
    return instance.delete(`${URLS.attendeeCourseWaitingPosition}/${id}`);
  },
  updateAttendeeCourseWaitingPosition(
    attendeeCourseWaitingPosition: AttendeeCourseWaitingPosition
  ): AxiosPromise<any> {
    logger.debug('updateAttendeeCourseWaitingPosition');
    if (!isEmptyId(attendeeCourseWaitingPosition.id)) {
      return instance.put<any>(
        `${URLS.attendeeCourseWaitingPosition}/${attendeeCourseWaitingPosition.id}`,
        attendeeCourseWaitingPositionFuncs.toAPI(attendeeCourseWaitingPosition)
      );
    } else {
      return instance.post<{ id: string; }>(
        `${URLS.attendeeCourseWaitingPosition}`,
        attendeeCourseWaitingPositionFuncs.toAPI(attendeeCourseWaitingPosition)
      );
    }
  },
  downloadPdf(dateTimeId: string, lang: string): AxiosPromise<any> {
    return instance.get(
      `${URLS.attendeeCourseWaitingPosition}/CreatePrintPdf/${dateTimeId}?lang=${lang}`,
      { responseType: 'blob' }
    );
  }
};