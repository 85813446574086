import { GetterTree } from 'vuex';
import { AttendeeCourseWaitingPositionState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AttendeeCourseWaitingPositionState, RootState> = {
  ["getAttendeeCourseWaitingPositions"]: (state) =>
    state.attendeeCourseWaitingPositions,
  ["getAttendeeCourseWaitingPositionsIsLoading"]: (state) =>
    state.attendeeCourseWaitingPositions?.isLoading,
  ["getAttendeeCourseWaitingPositionsTotal"]: (state) =>
    state.attendeeCourseWaitingPositions?.total,
  ["getAttendeeCourseWaitingPositionsSearchParams"]: (state) =>
    state.attendeeCourseWaitingPositions?.searchParams,
};
