import { AttendeeCourseWaitingPositionState } from './types';

export const state = (): AttendeeCourseWaitingPositionState => initialState();

export const initialState = (): AttendeeCourseWaitingPositionState => ({
  attendeeCourseWaitingPositions: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['position'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['firstName', 'lastName', 'email'],
    },
  },
});
