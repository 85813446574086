import { GetterTree } from 'vuex';
import { AttendeeState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<AttendeeState, RootState> = {
  ['getAttendees']: state => state.attendees,
  ['getAttendeesIsLoading']: state => state.attendees?.isLoading,
  ['getAttendeesTotal']: state => state.attendees?.total,
  ['getAttendeesSearchParams']: state => state.attendees?.searchParams,

  ['getInterested']: state => state.interested,
  ['getInterestedIsLoading']: state => state.interested?.isLoading,
  ['getInterestedTotal']: state => state.interested?.total,
  ['getInterestedSearchParams']: state => state.interested?.searchParams,

  ['getApplicationUsers']: state => state.applicationUsers,
  ['getApplicationUsersIsLoading']: state => state.applicationUsers?.isLoading,
  ['getApplicationUsersTotal']: state => state.applicationUsers?.total,
  ['getApplicationUsersSearchParams']: state => state.applicationUsers?.searchParams,

  ['getWaitingList']: state => state.waitingList,
  ['getWaitingListIsLoading']: state => state.waitingList?.isLoading,
  ['getWaitingListTotal']: state => state.waitingList?.total,
  ['getWaitingListSearchParams']: state => state.waitingList?.searchParams,
};
