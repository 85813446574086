import { ActionTree } from 'vuex';
import { AttendeeCourseWaitingPositionState } from './types';
import { RootState } from '../../types';
import { defaultBackendAttendeeCourseWaitingPosition } from '@/shared/backend/attendeeCourseWaitingPosition';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { AttendeeCourseWaitingPosition, Value } from '@/shared/model/attendeeCourseWaitingPosition';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import DownloadUtils from '@/shared/utils/downloadUtils';

const logger = new Logger('actions.attendeeCourseWaitingPositions');
export const actions: ActionTree<AttendeeCourseWaitingPositionState, RootState> = {
  getAttendeeCourseWaitingPositions(
    { commit, getters },
    payload?: { searchParams?: any; dateTimeId?: string; noStore?: boolean }
  ) {
    commit('setAttendeeCourseWaitingPositionsIsLoading', true);
    let searchParams =
      payload?.searchParams ??
      getters.getAttendeeCourseWaitingPositionsSearchParams;
    return defaultBackendAttendeeCourseWaitingPosition
      .getAttendeeCourseWaitingPositions(searchParams, payload?.dateTimeId)
      .then((response: AxiosResponse<Value>) => {
        if (payload?.noStore) return response.data;
        commit('setAttendeeCourseWaitingPositions', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit('setAttendeeCourseWaitingPositionsIsLoading', false);
      });
  },
  updateAttendeeCourseWaitingPosition({ commit, dispatch }, entity: AttendeeCourseWaitingPosition) {
    return defaultBackendAttendeeCourseWaitingPosition
      .updateAttendeeCourseWaitingPosition(entity)
      .then(resp => {
          moduleUtils.ok(i18nGlobal.t(`success.attendee-course-waiting-position_update`), commit);
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteAttendeeCourseWaitingPosition({ commit, dispatch }, id: string) {
    return defaultBackendAttendeeCourseWaitingPosition
      .deleteAttendeeCourseWaitingPosition(id)
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  async downloadPdf({ commit }, dateTimeId: string) {
    const lang = i18nGlobal.locale.value;
    try {
      await defaultBackendAttendeeCourseWaitingPosition
        .downloadPdf(dateTimeId, lang)
        .then((response) => {
          const fileName = DownloadUtils.getFileName(
            response.headers["content-disposition"]
          );
          const type = response.headers["content-type"];
          if (DownloadUtils.isIeOrEdge(window)) {
            const file = new File([response.data], fileName, {
              type: type,
            });
            (window.navigator as any).msSaveOrOpenBlob(file, fileName);
          } else {
            const blob = new Blob([response.data], { type: type });
            const url = DownloadUtils.createDownloadLink(blob, fileName);
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      logger.error(e);
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
      throw e;
    }
  }
};