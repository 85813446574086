export enum ROUTES {
  public_view = 'public-view',
  //Used in `$router.push()`
  login = 'login',
  login_attendee = 'login',

  register_attendee = 'register-attendee',
  my_profile = 'my-profile',
  my_company = 'my-company',
  my_invoices = 'my-invoices',

  courses = 'courses',
  course = 'course',

  locations = 'locations',
  location = 'location',

  display_qr_code = 'display-qr-code',
  print_qr_code = 'print-qr-code',
  print_attendance_list = 'print-attendance-list',

  attendances = 'attendances',

  trainers = 'trainers',
  trainer = 'trainer',

  attendees = 'attendees',
  attendees_interested = 'attendees-interested',
  attendee = 'attendee',
  attendee_interested = 'attendee-interested',

  employees = 'employees',
  employee = 'employee',

  invoices = 'invoices',

  invoice_items = 'invoice-items',

  new_password = 'new-password',

  attendee_registration = 'attendee-registration',

  membership_application = 'membership-application',

  membership_application_confirmation = 'membership-application-confirmation',

  attendee_registration_to_appointment = 'attendee-registration-to-appointment',

  checkIn_scanner = 'checkIn-scanner',

  error_view = 'error-view',

  reference_tables = 'reference-tables',

  self_checkin = 'self-checkin',

  settings = 'settings',

  attendee_presences = 'attendee-presences',

  attendances_presences = 'presences',

  courses_coursetimes = 'course-times',
  courses_appointments = 'appointments',
  event_appointment = 'event-appointment',

  conflicts = 'conflicts',

  tasks = 'tasks',

  cancel_appointment = 'cancel-appointment',

  my_documents = 'my-documents',
  my_membership = 'my-membership',

  company_subscriptions = 'company-subscriptions',

  statistics = 'statistics',

  direct_debit_creation = 'direct-debit-creation',

  create_company = 'create-company',
  home = 'home',
}
