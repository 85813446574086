export interface Settings {
  id?: string; // System.Guid
  companyId?: string; // System.Guid
  color: string; // string
  logoFile: string; // byte[]
  appointmentBookingInAdvanceDays: number; //ushort
  membershipSubscriptionTerminationDate: TerminationDate;
  membershipSubscriptionTerminationInAdvanceWeeks: number; //byte
  halfYearlyPaymentIntervalStartMonth: number; //1-12
  allowMonthlyPaymentInterval: boolean;
  allowQuarterlyPaymentInterval: boolean;
  allowHalfYearlyPaymentInterval: boolean;
  allowYearlyPaymentInterval: boolean;
  showCheckinNotePopup: boolean;
}

export interface FileDto {
  name: string;
  createdDate: string;
  size: number;
  creatorFullName: string;
  filePath: string;
}

export interface FileValue {
  value: FileDto[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

function fileParse(data?: Partial<FileDto>): FileDto {
  return {
    name: data?.name || '',
    createdDate: data?.createdDate || '',
    size: data?.size || 0,
    creatorFullName: data?.creatorFullName || '',
    filePath: data?.filePath || '',
  };
}

export enum ImportTemplate {
  LOCATION = 'location',
  ROOM = 'room',
  EMPLOYEE = 'employee',
  TRAINER = 'trainer',
  TRAINER_SKILL = 'trainer_skill',
  ATTENDEE = 'attendee',
  COURSE = 'course',
  COURSE_TIME = 'course_time',
  SKILL = 'skill',
  CHARACTERISTIC = 'characteristic',
  DEPARTMENT = 'department',
  MEMBER_STATUS = 'member_status',
  TRAINER_AVAILABILITY = 'trainer_availability',
  MEMBERSHIP_TYPE = 'membership_type',
  COST_SCHEME = 'cost_scheme',
  MEMBERSHIP_TYPE_ATTENDEE = 'membership_type_attendee',
}

export enum ImportLogDialogState {
  Loading = 0,
  Success = 1,
  Failure = 2,
}

export enum TerminationDate {
  EndOfMonth = 0,
  EndOfQuarter = 1,
  EndOfYear = 2,
}

export interface Value {
  value: Settings[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

const defaultAppointmentBookingInAdvanceDays = 30;
const defaultMembershipSubscriptionTerminationDate = TerminationDate.EndOfMonth;
const defaultMembershipSubscriptionTerminationInAdvanceWeeks = 4;
const defaultCompanyColor = '#4b6084';
const defaultHalfYearPaymentIntervalStartMonth = 1;

// init api object
function toAPI(data: Partial<Settings>): any {
  return {
    Id: data?.id || undefined,
    CompanyId: data?.companyId || undefined,
    Color: data?.color || "",
    AppointmentBookingInAdvanceDays:
      data?.appointmentBookingInAdvanceDays ??
      defaultAppointmentBookingInAdvanceDays,
    MembershipSubscriptionTerminationDate:
      data?.membershipSubscriptionTerminationDate ??
      defaultMembershipSubscriptionTerminationDate,
    MembershipSubscriptionTerminationInAdvanceWeeks:
      data?.membershipSubscriptionTerminationInAdvanceWeeks ??
      defaultMembershipSubscriptionTerminationInAdvanceWeeks,
    HalfYearlyPaymentIntervalStartMonth:
      data?.halfYearlyPaymentIntervalStartMonth ??
      defaultHalfYearPaymentIntervalStartMonth,
    AllowMonthlyPaymentInterval: data?.allowMonthlyPaymentInterval ?? true,
    AllowQuarterlyPaymentInterval: data?.allowQuarterlyPaymentInterval ?? true,
    AllowHalfYearlyPaymentInterval:
      data?.allowHalfYearlyPaymentInterval ?? true,
    AllowYearlyPaymentInterval: data?.allowYearlyPaymentInterval ?? true,
    ShowCheckinNotePopup: data?.showCheckinNotePopup ?? false,
  };
}

function parse(data?: Partial<Settings>): Settings {
  return {
    id: data?.id || undefined,
    companyId: data?.companyId || undefined,
    color: data?.color || defaultCompanyColor,
    logoFile: data?.logoFile || "",
    appointmentBookingInAdvanceDays:
      data?.appointmentBookingInAdvanceDays ??
      defaultAppointmentBookingInAdvanceDays,
    membershipSubscriptionTerminationDate:
      data?.membershipSubscriptionTerminationDate ??
      defaultMembershipSubscriptionTerminationDate,
    membershipSubscriptionTerminationInAdvanceWeeks:
      data?.membershipSubscriptionTerminationInAdvanceWeeks ??
      defaultMembershipSubscriptionTerminationInAdvanceWeeks,
    halfYearlyPaymentIntervalStartMonth:
      data?.halfYearlyPaymentIntervalStartMonth ??
      defaultHalfYearPaymentIntervalStartMonth,
    allowMonthlyPaymentInterval: data?.allowMonthlyPaymentInterval ?? true,
    allowQuarterlyPaymentInterval: data?.allowQuarterlyPaymentInterval ?? true,
    allowHalfYearlyPaymentInterval:
      data?.allowHalfYearlyPaymentInterval ?? true,
    allowYearlyPaymentInterval: data?.allowYearlyPaymentInterval ?? true,
    showCheckinNotePopup: data?.showCheckinNotePopup ?? false,
  };
}

export default {
  parse,
  toAPI: toAPI,
  fileParse,
};
