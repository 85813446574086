import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import attendance, { Attendance, Value } from '../model/attendance';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';
import moment from 'moment';
import DateUtils from '../utils/dateUtils';
import {
  ParticipationStatusType,
  getStatusTypeText,
} from '../model/attendance';

const logger = new Logger('backend.Attendances');
export interface BackendAttendance {
  getAttendance(id: string): AxiosPromise<Attendance>;
  getAttendances: (
    searchParams: SearchParams,
    attendeeId: string,
    companyId: string,
    locationId: string | undefined,
    departmentId: string | undefined,
    currentDateTime: string | undefined,
    filterByCourseId: string | undefined,
    dateFrom: string | undefined,
    dateTill: string | undefined,
    appointmentId: string | undefined,
    timeFrom: string | undefined,
    timeTill: string | undefined,
    statuses: string[] | undefined
  ) => AxiosPromise<Value>;
  getAttendancesForCheckIn: (
    searchParams: SearchParams,
    locationId: string,
    currentDateTime: string,
    attendeeId?: string,
    trainerId?: string
  ) => AxiosPromise<Value>;
  getAttendancesForTrainer: (
    searchParams: SearchParams,
    trainerId: string,
    companyId: string,
    onlyAttendancesOfTrainer: boolean,
    locationId: string | undefined,
    departmentId: string | undefined,
    statuses: string[] | undefined,
    currentDateTime: string | undefined
  ) => AxiosPromise<Value>;
  deleteAttendance(id: string): AxiosPromise;
  updateAttendances(attendances: Attendance[]): AxiosPromise<any>;
  setConfirmedStatusMultiple(
    confirmedAttendances: Attendance[],
    cancelledAttendances: Attendance[]
  ): AxiosPromise<any>;
  setCancelledStatusMultipleByCourse(
    courseId: string,
    attendeeId?: string
  ): AxiosPromise<any>;
  updateAttendance(Attendance: Attendance): AxiosPromise<any>;
  cancelAttendance(id: string): AxiosPromise;
  cancelAttendanceAnonymous(id: string): AxiosPromise;
  cancelAttendanceByAppointmentId(appointmentId: string): AxiosPromise;
  exportConfirmedAttendances(
    fromDate: string,
    tillDate: string,
    exportAsPdf: boolean,
    attendeeId?: string,
    lang?: string
  ): AxiosPromise<any>;
}

export const defaultBackendAttendance: BackendAttendance = {
  getAttendance(id: string): AxiosPromise<Attendance> {
    let url = `${URLS.attendancesOdata}/${id}`;
    return instance.get<Attendance>(url);
  },
  getAttendances(
    searchParams: SearchParams,
    attendeeId: string,
    companyId: string,
    locationId: string | undefined,
    departmentId: string | undefined,
    currentDateTime: string | undefined,
    filterByCourseId: string | undefined,
    dateFrom: string | undefined,
    dateTill: string | undefined,
    appointmentId: string | undefined,
    timeFrom: string | undefined,
    timeTill: string | undefined,
    statuses: string[] | undefined
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    if (companyId != CONST.emptyGuid) {
      odfb.eq(CONST.CompanyId, companyId, false);
    }

    if (attendeeId != CONST.emptyGuid) {
      odfb.eq(CONST.AttendeeId, attendeeId, false);
    }
    if (currentDateTime != undefined && currentDateTime !== '') {
      let momentCurrentDateTime = moment(currentDateTime);
      odfb.ge(
        CONST.BookingDate,
        momentCurrentDateTime.startOf('day').toISOString(),
        false
      );
      odfb.lt(
        CONST.BookingDate,
        momentCurrentDateTime.endOf('day').toISOString(),
        false
      );
    }
    if (filterByCourseId != undefined) {
      odfb.eq(CONST.CourseId, filterByCourseId, false);
    }
    if (dateFrom != undefined) {
      let momentDateFrom = moment(dateFrom);
      odfb.ge(
        CONST.BookingDate,
        momentDateFrom.startOf('day').toISOString(),
        false
      );
    }
    if (dateTill != undefined) {
      let momentDateTill = moment(dateTill);
      odfb.lt(
        CONST.BookingDate,
        momentDateTill.endOf('day').toISOString(),
        false
      );
    }
    if (appointmentId != undefined) {
      odfb.eq(CONST.AppointmentId, appointmentId, false);
    }

    if (locationId != undefined) {
      odfb.eq(CONST.LocationId, locationId, false);
    }

    if (timeFrom != undefined) {
      odfb.ge(CONST.TimeFrom, DateUtils.toTimespanString(timeFrom));
    }
    if (timeTill != undefined) {
      odfb.le(CONST.TimeFrom, DateUtils.toTimespanString(timeTill));
    }

    if (statuses != undefined && statuses.length > 0) {
      let odfbStatuses = ODataFilterBuilder('or');
      for (let status of statuses) {
        odfbStatuses.eq(CONST.Status, status);
      }
      odfb.and(odfbStatuses);
    }

    let baseUrl;
    if (departmentId != undefined) {
      baseUrl = `${URLS.attendancesOdata}/GetInDepartment(departmentId=${departmentId},trainerId=null)`;
    } else {
      baseUrl = `${URLS.attendancesOdata}`;
    }

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getAttendances${url}`);
    return instance.get<Value>(url);
  },
  getAttendancesForCheckIn(
    searchParams: SearchParams,
    locationId: string,
    currentDateTime: string,
    attendeeId?: string,
    trainerId?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    if (attendeeId) {
      odfb.eq(CONST.AttendeeId, attendeeId, false);
    } else if (trainerId) {
      odfb.eq(CONST.TrainerId, trainerId, false);
    }

    odfb.eq(CONST.LocationId, locationId, false);
    odfb.ne(CONST.Status, getStatusTypeText(ParticipationStatusType.Requested));
    odfb.ne(
      CONST.Status,
      getStatusTypeText(ParticipationStatusType.OnWaitingList)
    );

    let momentCurrentDateTime = moment(currentDateTime);
    odfb.ge(
      CONST.BookingDate,
      momentCurrentDateTime.startOf('day').toISOString(),
      false
    );
    odfb.lt(
      CONST.BookingDate,
      momentCurrentDateTime.endOf('day').toISOString(),
      false
    );

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.attendancesOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getAttendances${url}`);
    return instance.get<Value>(url);
  },

  getAttendancesForTrainer: (
    searchParams: SearchParams,
    trainerId: string,
    companyId: string,
    onlyAttendancesOfTrainer: boolean,
    locationId: string | undefined,
    departmentId: string | undefined,
    statuses: string[] | undefined,
    currentDateTime: string | undefined
  ): AxiosPromise<Value> => {
    let odfb = ODataFilterBuilder('and');

    if (companyId != CONST.emptyGuid) {
      odfb.eq(CONST.CompanyId, companyId, false);
    }

    if (locationId != undefined) {
      odfb.eq(CONST.LocationId, locationId, false);
    }

    if (statuses != undefined && statuses.length > 0) {
      let odfbStatuses = ODataFilterBuilder('or');
      for (let status of statuses) {
        odfbStatuses.eq(CONST.Status, status);
      }
      odfb.and(odfbStatuses);
    }

    if (currentDateTime != undefined) {
      let momentCurrentDateTime = moment(currentDateTime);
      odfb.ge(
        CONST.BookingDate,
        momentCurrentDateTime.startOf('day').toISOString(),
        false
      );
      odfb.lt(
        CONST.BookingDate,
        momentCurrentDateTime.endOf('day').toISOString(),
        false
      );
    }

    let baseUrl;

    //Attendances of the trainer him/herself
    if (onlyAttendancesOfTrainer) {
      if (departmentId != undefined) {
        baseUrl = `${URLS.attendancesOdata}/GetInDepartment(departmentId=${departmentId},trainerId=null)`;
      } else {
        baseUrl = `${URLS.attendancesOdata}`;
      }
      odfb.eq(CONST.TrainerId, trainerId, false);
    }
    //Attendances where the trainer has the trainer role or is the responsible trainer of the course.
    else {
      if (departmentId != undefined) {
        baseUrl = `${URLS.attendancesOdata}/GetInDepartment(departmentId=${departmentId},trainerId=${trainerId})`;
      } else {
        baseUrl = `${URLS.attendancesOdata}/GetByTrainerId(trainerId=${trainerId})`;
      }
    }

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getAttendancesForTrainer${url}`);
    return instance.get<Value>(url);
  },

  deleteAttendance(id: string): AxiosPromise {
    logger.debug('deleteAttendances');
    return instance.delete(`${URLS.attendances}/${id}`);
  },
  cancelAttendance(id: string): AxiosPromise {
    logger.debug('cancelAttendance');
    return instance.put(`${URLS.attendances}/Cancel/${id}`);
  },
  cancelAttendanceAnonymous(id: string): AxiosPromise {
    logger.debug('cancelAttendanceAnonymous');
    return instance.put(`${URLS.attendances}/CancelAnonymous/${id}`);
  },

  cancelAttendanceByAppointmentId(appointmentId: string): AxiosPromise {
    logger.debug('cancelAttendanceByAppointmentId');
    return instance.put(
      `${URLS.attendances}/CancelByAppointmentId/${appointmentId}`
    );
  },
  updateAttendance(Attendance: Attendance): AxiosPromise<any> {
    logger.debug('updateAttendance');
    if (Attendance.id != undefined) {
      return instance.put<Attendance>(
        `${URLS.attendances}`,
        attendance.toAPI(Attendance)
      );
    } else {
      return instance.post<Attendance>(
        `${URLS.attendances}`,
        attendance.toAPI(Attendance)
      );
    }
  },
  updateAttendances(Attendances: Attendance[]): AxiosPromise<any> {
    logger.debug('updateAttendances');
    return instance.post<Attendance[]>(
      `${URLS.attendances}/AddMultiple`,
      attendance.arrayToAPI(Attendances)
    );
  },
  setConfirmedStatusMultiple(
    confirmedAttendances: Attendance[],
    cancelledAttendances: Attendance[]
  ): AxiosPromise<any> {
    logger.debug('setConfirmedStatusMultiple');
    return instance.post<Attendance>(
      `${URLS.attendances}/SetConfirmedStatusMultiple`,
      {
        ConfirmedAttendances: attendance.arrayToAPI(confirmedAttendances),
        CancelledAttendances: attendance.arrayToAPI(cancelledAttendances),
      }
    );
  },
  setCancelledStatusMultipleByCourse(
    courseId: string,
    attendeeId?: string
  ): AxiosPromise<any> {
    logger.debug('setCancelledStatusMultipleByCourse');
    return instance.post<Attendance>(
      `${URLS.attendances}/SetCancelledStatusMultipleByCourse`,
      {
        CourseId: courseId,
        AttendeeId: attendeeId,
        ClientLocalDateTime: DateUtils.getCurrentLocalDateAsIsoString(),
      }
    );
  },
  exportConfirmedAttendances(
    fromDate: string,
    tillDate: string,
    exportAsPdf: boolean,
    attendeeId?: string,
    lang?: string
  ): AxiosPromise<any> {
    let url = `${URLS.attendances}/ExportConfirmed`;
    if (lang) url += `?lang=${lang}`;

    return instance.post(
      url,
      {
        AttendeeId: attendeeId,
        FromDate: fromDate,
        TillDate: tillDate,
        ExportPdf: exportAsPdf,
      },
      { responseType: 'blob' }
    );
  },
};
