import { AttendeeState } from './types';

export const state = (): AttendeeState => initialState();

export const initialState = (): AttendeeState => ({
  attendees: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['isOnline', 'active', 'lastName', 'firstName'],
        sortDesc: [true, true, false, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [
        'lastName',
        'firstName',
        'email',
        'userName',
        'memberNumber',
        'departmentNames',
        'mainLocationName',
        'additionalLocationNames'
      ],
    },
  },
  interested: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['isOnline', 'lastName', 'firstName'],
        sortDesc: [true, false, false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [
        'lastName',
        'firstName',
        'email',
        'departmentNames',
        'mainLocationName',
        'additionalLocationNames'
      ],
    },
  },
  waitingList: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['waitingListNumber'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['lastName', 'firstName', 'email'],
    },
  },
  applicationUsers: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['userName'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['userName', 'email', 'lastName', 'firstName'],
    },
  },
  attendeesWithoutMembershipTotalOpenCount: 0
});
