import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, mergeProps as _mergeProps, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_tooltip_text = _resolveComponent("tooltip-text")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_select = _resolveComponent("v-select")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'d-flex justify-center margin-location-mobile' : _ctx.mobile, 'd-flex justify-center' : !_ctx.mobile})
  }, [
    _createVNode(_component_v_select, {
      items: _ctx.selectboxEntries,
      "model-value": _ctx.selectedContactId,
      "item-title": "name",
      "item-value": "id",
      "hide-details": "",
      variant: "solo",
      flat: "",
      class: "max-width",
      "onUpdate:modelValue": _ctx.selectboxEntryChanged
    }, {
      selection: _withCtx(({ item }) => [
        (item != undefined)
          ? (_openBlock(), _createBlock(_component_tooltip_text, {
              key: 0,
              maxLength: 24,
              text: item.title,
              class: _normalizeClass([{'location-entry-mobile text-h6' : _ctx.mobile, 'location-entry-desktop text-h5' : !_ctx.mobile}, "font-weight-medium"])
            }, null, 8, ["text", "class"]))
          : _createCommentVNode("", true)
      ]),
      item: _withCtx(({ item, props }) => [
        (item != undefined)
          ? (_openBlock(), _createBlock(_component_v_list_item, _normalizeProps(_mergeProps({ key: 0 }, props)), {
              title: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass([{'location-entry-mobile text-body-2' : _ctx.mobile, 'location-entry-desktop text-body-1' : !_ctx.mobile}, "pa-2 font-weight-regular"])
                }, _toDisplayString(item.title), 3)
              ]),
              _: 2
            }, 1040))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["items", "model-value", "onUpdate:modelValue"])
  ], 2))
}