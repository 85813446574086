import { ActionTree } from 'vuex';
import { TaskRequestState } from './types';
import { RootState } from '../../types';
import { defaultBackendTaskRequest } from '@/shared/backend/taskRequest';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import {
  Value,
  TaskRequest,
  TaskRequestStatus,
} from '@/shared/model/taskRequest';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';

const logger = new Logger('actions.taskRequests');
export const actions: ActionTree<TaskRequestState, RootState> = {
  getTaskRequests(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; }
  ) {
    commit('setTaskRequestsIsLoading', true);
    const searchParams =
      payload?.searchParams ?? getters.getTaskRequestsSearchParams;
    return defaultBackendTaskRequest
      .getTaskRequests(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setTaskRequests', response.data);
        commit('setTaskRequestsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTaskRequestsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  getTaskRequestsAttendances(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      startDate?: string;
      endDate?: string;
      statuses?: TaskRequestStatus[];
      locationId?: string;
      departmentId?: string;
    }
  ) {
    commit('setTaskRequestsIsLoading', true);
    const searchParams =
      payload?.searchParams ?? getters.getTaskRequestsSearchParams;
    const startDate = payload?.startDate ?? '';
    const endDate = payload?.endDate ?? '';
    const statuses = payload?.statuses ?? [];
    const locationId = payload?.locationId ?? undefined;
    const departmentId = payload?.departmentId ?? undefined;

    return defaultBackendTaskRequest
      .getTaskRequestsAttendances(
        searchParams,
        startDate,
        endDate,
        statuses,
        locationId,
        departmentId
      ).then((response: AxiosResponse<Value>) => {
        commit('setTaskRequests', response.data);
        commit('setTaskRequestsIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setTaskRequestsIsLoading', false);
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  getTaskRequestsAttendancesOpenCount(
    {commit},
    payload?: {locationId?: string; departmentId?: string;}) {
    return defaultBackendTaskRequest
      .getTaskRequestsAttendancesOpenCount(
        payload?.locationId,
        payload?.departmentId
      ).then((response: AxiosResponse<Value>) => {
        commit('setTaskRequestsAttendancesOpenCount', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  getTaskRequestsTotalOpenCount({ commit, dispatch, getters, rootGetters }) {
    return defaultBackendTaskRequest
      .getTaskRequestsTotalOpenCount()
      .then((response: AxiosResponse<Value>) => {
        commit('setTaskRequestsTotalOpenCount', response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        commit('setTaskRequestsTotalOpenCount', undefined);
        throw e;
      });
  },

  getTaskRequest({ commit, dispatch }, id: string) {
    return defaultBackendTaskRequest
      .getTaskRequest(id)
      .then((response: AxiosResponse<TaskRequest>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateTaskRequest({ commit, dispatch }, file: TaskRequest) {
    return defaultBackendTaskRequest
      .updateTaskRequest(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TaskRequest;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.taskRequest_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  createTaskRequestAttendances(
    { commit, dispatch },
    payload?: { attendancesIds?: string[] }
  ) {
    const attendancesIds = payload?.attendancesIds ?? [];
    if (attendancesIds.length === 0) {
      return;
    }
    return defaultBackendTaskRequest
      .createTaskRequestAttendances(attendancesIds)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TaskRequest;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.taskRequest_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateTaskRequestAttendancesStatusMultiple(
    { commit, dispatch },
    payload?: { attendancesIds?: string[]; status?: TaskRequestStatus }
  ) {
    const attendancesIds = payload?.attendancesIds ?? [];
    const status = payload?.status ?? '';
    if (attendancesIds.length === 0 || status === '') {
      return;
    }
    return defaultBackendTaskRequest
      .updateTaskRequestAttendancesStatusMultiple(attendancesIds, status)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TaskRequest;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.taskRequest_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateAttendancesStatusMultiple(
    { commit, dispatch },
    payload?: { attendancesIds?: string[]; status?: TaskRequestStatus }
  ) {
    const attendancesIds = payload?.attendancesIds ?? [];
    const status = payload?.status ?? '';
    if (attendancesIds.length === 0 || status === '') {
      return;
    }
    return defaultBackendTaskRequest
      .updateAttendancesStatusMultiple(attendancesIds, status)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TaskRequest;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.taskRequest_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },

  deleteTaskRequest({ commit, dispatch }, id: string) {
    return defaultBackendTaskRequest
      .deleteTaskRequest(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: TaskRequest;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error('error', commit, e, logger);
        throw e;
      });
  },
};
