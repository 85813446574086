import password from '@/components/authorization/login/password/password';
import { CONST } from '../utils/constants';
import DateUtils from '../utils/dateUtils';
import { Salutation } from './salutation';

export interface ApplicationUser {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  salutation: Salutation;
  titel: string;
  email: string;
  phone: string;
  userName: string;
  password: string;
  authorities: string;
  emailConfirmed: boolean;
  fromDate: string;
  tillDate: string;
  role: string;
  token: string;
  active: boolean;
  type: number;
  teams: string;
  tickets: number;
  isIntern: boolean;
  birthday: string;
  street: string;
  zip: string;
  city: string;
  logo?: string;
  logoId?: string;
  attendeeId?: string;
  companyId: string;
  memberStatusId?: string;
  unlocked: boolean;
  agreedWithReceivingEmails: boolean;
}

export interface Value {
  value: ApplicationUser[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

export interface Password {
  data: string;
}

export interface EmployeePassword {
  password: string;
  userToken?: string;
  userId?: string;
}

// init api object
function toAPIEmployeePassword(data: Partial<EmployeePassword>): any {
  return {
    Password: data?.password || undefined,
    UserToken: data?.userToken || undefined,
    UserId: data?.userId || undefined,
  };
}

function toAPI(data: Partial<ApplicationUser>): any {
  return {
    Id: data?.id || undefined,
    FirstName: data?.firstName || '',
    LastName: data?.lastName || '',
    Salutation: data?.salutation || '',
    UserName: data?.userName || '',
    Password: data?.password || undefined,
    Title: data?.titel || '',
    Email: data?.email || '',
    Phone: data?.phone || '',
    Text: null,
    Zip: data?.zip || '',
    Street: data?.street || '',
    City: data?.city || '',
    Active: data?.active || false,
    FromDate: data?.fromDate || new Date().toISOString(),
    TillDate: DateUtils.dateNullableToApi(data?.tillDate),
    Birthday: data?.birthday || '',
    MemberStatusId: data?.memberStatusId || undefined,
    Unlocked: data?.unlocked ?? false,
    AgreedWithReceivingEmails: data?.agreedWithReceivingEmails ?? true,
    AttendeeId: data?.attendeeId || undefined,
  };
}

export default {
  toAPIEmployeePassword: toAPIEmployeePassword,
  toAPI: toAPI,
};

export const EmptyApplicationUser: ApplicationUser = {
  id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  salutation: '',
  email: '',
  phone: '',
  userName: '',
  password: '',
  authorities: '',
  emailConfirmed: false,
  fromDate: '',
  tillDate: '',
  role: '',
  token: '',
  active: false,
  titel: '',
  birthday: '',
  zip: '',
  street: '',
  city: '',
  type: 0,
  teams: '',
  tickets: 0,
  isIntern: true,
  companyId: CONST.emptyGuid,
  memberStatusId: undefined,
  unlocked: false,
  agreedWithReceivingEmails: true,
};
