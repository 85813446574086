import { ActionTree } from 'vuex';
import { QrCodeState } from './types';
import { RootState } from '../../types';
import { defaultBackendQrCode } from '@/shared/backend/qrCode';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import { Value, QrCode } from '@/shared/model/qrCode';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { CONST } from '../../../utils/constants';
import DownloadUtils from '@/shared/utils/downloadUtils';

const logger = new Logger('actions.qrCodes');
export const actions: ActionTree<QrCodeState, RootState> = {
  getQrCodes(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      locationId?: string;
      courseId?: string;
      active?: boolean;
      availableAtDate?: string;
    }
  ) {
    commit("setQrCodesIsLoading", true);
    let searchParams = payload?.searchParams ?? getters.getQrCodesSearchParams;
    let locationId = payload?.locationId ?? CONST.emptyGuid;
    let courseId = payload?.courseId ?? CONST.emptyGuid;
    return defaultBackendQrCode
      .getQrCodes(
        searchParams,
        locationId,
        courseId,
        payload?.active,
        payload?.availableAtDate
      )
      .then((response: AxiosResponse<Value>) => {
        commit("setQrCodes", response.data);
        commit("setQrCodesIsLoading", false);
        return response.data;
      })
      .catch((e: any) => {
        commit("setQrCodesIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  getQrCode({ commit, dispatch }, id: string) {
    return defaultBackendQrCode
      .getQrCode(id)
      .then((response: AxiosResponse<QrCode>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  getLatestQrCode({ commit }, courseId: string) {
    return defaultBackendQrCode
      .getLatestQrCode(courseId)
      .then((response: AxiosResponse<QrCode>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  updateQrCode({ commit, dispatch }, file: QrCode) {
    return defaultBackendQrCode
      .updateQrCode(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: QrCode;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.qrCode_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  deleteQrCode({ commit, dispatch }, id: string) {
    return defaultBackendQrCode
      .deleteQrCode(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: QrCode;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  getCoordinatesFromAddress({ commit, dispatch }, address: string) {
    return defaultBackendQrCode
      .getCoordinatesFromAddress(address)
      .then(
        (
          response: AxiosResponse<{
            data: {
              IsValid: boolean;
              lat: string;
              lng: string;
              errorMessage: string;
            };
          }>
        ) => {
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  async downloadForUsers(
    { commit },
    payload: {
      urlPrefix: string;
      applicationUserIds?: string[];
      attendeeIds?: string[];
    }
  ) {
    try {
      await defaultBackendQrCode
        .downloadForUsers(
          payload.urlPrefix,
          payload.applicationUserIds,
          payload.attendeeIds
        )
        .then((response) => {
          const fileName = DownloadUtils.getFileName(
            response.headers["content-disposition"]
          );
          const type = response.headers["content-type"];
          if (DownloadUtils.isIeOrEdge(window)) {
            const file = new File([response.data], fileName, {
              type: type,
            });
            (window.navigator as any).msSaveOrOpenBlob(file, fileName);
          } else {
            const blob = new Blob([response.data], { type: type });
            const url = DownloadUtils.createDownloadLink(blob, fileName);
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      logger.error(e);
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
    }
  },
};
