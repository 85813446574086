import DateUtils from '../utils/dateUtils';
import { isEmptyId } from '../utils/generalUtils';
import referenceParent, { ReferenceParent } from './referenceParent';
import { Salutation } from './salutation';

export interface Attendee {
  id?: string;
  applicationUserId?: string;
  logo: string;
  logoId?: string;
  firstName: string;
  lastName: string;
  salutation: Salutation;
  userName: string;
  title: string;
  email: string;
  phone: string;
  text: string;
  nr: number;
  zip: string;
  street: string;
  city: string;
  isRegistrated: boolean;
  isOnline: boolean;
  role: string;
  active: boolean;
  fromDate: string;
  tillDate: string;
  birthday: string;
  lastLoginDate: string;
  createdDate: string;
  creatorFullName: string;
  fingerprintId?: string;
  companyId?: string;
  mainLocationId?: string;
  attendancesCount: number;
  departmentNames: string;
  mainLocationName: string;
  additionalLocationNames: string;
  memberNumber: string;
  memberStatusId?: string;
  memberStatusName: string;
  unlocked: boolean;
  waitingListNumber: number;
  membershipTypes: ReferenceParent[];
  membershipText: string;
  agreedWithReceivingEmails: boolean;
  isEditable?: boolean;
  deletedDate?: string;
  isMainAttendee: boolean;
  mainAttendeeId?: string;
  readonly allLocationNamesString: string;
  readonly isInactiveOrExpired: boolean;
  readonly hasActiveMembership: boolean;
  readonly isInterested: boolean;
  readonly fullName: string;
}

export interface Value {
  value: Attendee[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Attendee>): any {
  return {
    Id: data?.id || undefined,
    ApplicationUserId: data?.applicationUserId || undefined,
    FirstName: data?.firstName || undefined,
    LastName: data?.lastName || undefined,
    Salutation: data?.salutation || '',
    UserName: data?.userName || '',
    Title: data?.title || '',
    Email: data?.email || undefined,
    Phone: data?.phone || undefined,
    Text: data?.text || '',
    Zip: data?.zip || '',
    Street: data?.street || '',
    City: data?.city || '',
    IsRegistrated: data?.isRegistrated || false,
    Authorities: data?.role || '',
    Active: data?.active || false,
    FromDate: data?.fromDate || '',
    TillDate: DateUtils.dateNullableToApi(data?.tillDate),
    Birthday: DateUtils.dateNullableToApi(data?.birthday),
    CompanyId: data?.companyId || undefined,
    MainLocationId: data?.mainLocationId || undefined,
    MemberNumber: data?.memberNumber || undefined,
    MemberStatusId: data?.memberStatusId || undefined,
    Unlocked: data?.unlocked ?? false,
    MembershipTypeAttendees:
      data?.membershipTypes?.map((x) => referenceParent.toAPI(x)) ?? [],
    MembershipText: data?.membershipText || undefined,
    AgreedWithReceivingEmails: data?.agreedWithReceivingEmails ?? true,
    IsMainAttendee: data?.isMainAttendee ?? false,
  };
}

function parse(data?: Partial<Attendee>): Attendee {
  return {
    id: data?.id || undefined,
    applicationUserId: data?.applicationUserId || undefined,
    logo: data?.logo || '',
    logoId: data?.logoId || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    salutation: data?.salutation || '',
    isOnline: data?.isOnline || false,
    title: data?.title || '',
    phone: data?.phone || '',
    email: data?.email || '',
    userName: data?.userName || '',
    text: data?.text || '',
    nr: data?.nr || 0,
    zip: data?.zip || '',
    street: data?.street || '',
    city: data?.city || '',
    isRegistrated: data?.isRegistrated || false,
    role: data?.role || '',
    active: data?.active || false,
    fromDate: DateUtils.parseDateFromBackend(
      data?.fromDate,
      DateUtils.getTodayDateAsIsoString()
    ),
    tillDate: DateUtils.parseDateFromBackend(data?.tillDate),
    birthday: DateUtils.parseDateFromBackend(data?.birthday),
    createdDate: data?.createdDate || '',
    creatorFullName: data?.creatorFullName || '',
    lastLoginDate: data?.lastLoginDate || '',
    fingerprintId: data?.fingerprintId || undefined,
    companyId: data?.companyId || undefined,
    mainLocationId: data?.mainLocationId || undefined,
    mainLocationName: data?.mainLocationName || '',
    attendancesCount: data?.attendancesCount || 0,
    departmentNames: data?.departmentNames || '',
    additionalLocationNames: data?.additionalLocationNames || '',
    memberNumber: data?.memberNumber || '',
    memberStatusId: data?.memberStatusId || undefined,
    memberStatusName: data?.memberStatusName || '',
    unlocked: data?.unlocked ?? false,
    waitingListNumber: data?.waitingListNumber ?? 0,
    membershipTypes: data?.membershipTypes
      ? data.membershipTypes.map(referenceParent.parse)
      : [],
    membershipText: data?.membershipText || '',
    agreedWithReceivingEmails: data?.agreedWithReceivingEmails ?? true,
    deletedDate: data?.deletedDate || undefined,
    isMainAttendee: data?.isMainAttendee ?? false,
    mainAttendeeId: data?.mainAttendeeId || undefined,
    get allLocationNamesString(): string {
      return `${this.mainLocationName}, ${this.additionalLocationNames}`;
    },
    get isInactiveOrExpired(): boolean {
      if (this.isInterested) return false;

      let todayDate = DateUtils.getTodayDateAsIsoString();
      return (
        !this.active ||
        (this.tillDate != '' && this.tillDate < todayDate) ||
        this.fromDate > todayDate
      );
    },
    get hasActiveMembership(): boolean {
      if (this.isInterested) return false;
      return this.membershipTypes.find((x) => x.isCurrentlyActive) != undefined;
    },
    get isInterested(): boolean {
      return isEmptyId(this.id);
    },
    get fullName() {
      return `${this.lastName}, ${this.firstName}`;
    },
  };
}

export const EmptyAttendee: Attendee = parse({});

export default {
  parse,
  toAPI: toAPI,
};
