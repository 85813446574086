import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import location, { Location, Value } from '../model/location';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/constants';

const logger = new Logger('backend.Location');
export interface BackendLocation {
  getLocation(id: string): AxiosPromise<Location>;
  getLocationAnonymous(id: string): AxiosPromise<Location>;
  getLocations: (
    searchParams: SearchParams,
    departmentId?: string,
    active?: boolean,
    alwaysIncludedLocationsIds?: string[],
    companyId?: string,
    locationId?: string,
  ) => AxiosPromise<Value>;
  getAllLocations: () => AxiosPromise<Value>;
  deleteLocation(id: string): AxiosPromise;
  updateLocation(Location: Location): AxiosPromise<any>;
  getOwnUserLocations: () => AxiosPromise<Value>;
  getLocationsAnonymous: (companyId: string) => AxiosPromise<Value>;
  checkIfLocationIsInUse: (locationId: string) => AxiosPromise<boolean>;
}

export const defaultBackendLocation: BackendLocation = {
  getLocation(id: string): AxiosPromise<Location> {
    let url = `${URLS.locationOdata}/${id}`;
    return instance.get<Location>(url);
  },

  getLocationAnonymous(id: string): AxiosPromise<Location> {
    let url = `${URLS.location}/GetAnonymous/${id}`;
    return instance.get<Location>(url);
  },

  getLocations(
    searchParams: SearchParams,
    departmentId?: string,
    active?: boolean,
    alwaysIncludedLocationsIds?: string[],
    companyId?: string,
    locationId?: string,
  ): AxiosPromise<Value> {
    var odfbOuter = ODataFilterBuilder('or');
    var odfbInner = ODataFilterBuilder('and');

    if (active !== undefined) {
      odfbInner.eq(CONST.Active, active);
    }

    if (locationId !== undefined) {
      odfbInner.eq(CONST.Id, locationId, false);
    }

    if (alwaysIncludedLocationsIds != undefined) {
      alwaysIncludedLocationsIds.forEach((id) =>
        odfbOuter.eq(CONST.Id, id, false)
      );
    }

    let baseUrl;
    if (departmentId != undefined) {
      baseUrl = `${URLS.locationOdata}/GetInDepartment(departmentId=${departmentId})`;
    } else if (companyId != undefined) {
      baseUrl = `${URLS.locationOdata}/GetOdataAnonymous(companyId=${companyId})`;
    } else {
      baseUrl = `${URLS.locationOdata}`;
    }

    odfbOuter.or(odfbInner);

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfbOuter
    );
    logger.log(`getLocations${url}`);
    return instance.get<Value>(url);
  },

  deleteLocation(id: string): AxiosPromise {
    logger.debug('deleteLocation');
    return instance.delete(`${URLS.location}/${id}`);
  },
  updateLocation(Location: Location): AxiosPromise<any> {
    logger.debug('updateLocation');
    if (Location.id != '' && Location.id != CONST.emptyGuid) {
      return instance.put<Location>(
        `${URLS.location}`,
        location.toAPI(Location)
      );
    } else {
      return instance.post<Location>(
        `${URLS.location}`,
        location.toAPI(Location)
      );
    }
  },
  getAllLocations(): AxiosPromise<Value> {
    let url = DefaultBackendHelper.makeUrl(`${URLS.location}/GetAll`);
    logger.log(`getAllLocations${url}`);
    return instance.get<Value>(url);
  },

  getLocationsAnonymous(companyId: string): AxiosPromise<Value> {
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.location}/GetLocationsAnonymous/${companyId}`
    );
    logger.log(`getLocationsAnonymous${url}`);
    return instance.get<Value>(url);
  },

  getOwnUserLocations(): AxiosPromise<Value> {
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.location}/GetOwnUserLocations`
    );
    logger.log(`GetOwnUserLocations: ${url}`);
    return instance.get<Value>(url);
  },

  checkIfLocationIsInUse(locationId: string): AxiosPromise<boolean> {
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.location}/CheckIfIsInUse/${locationId}`
    );
    logger.log(`checkIfLocationIsInUse: ${url}`);
    return instance.get<boolean>(url);
  }
};
