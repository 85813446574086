import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { ROUTES } from './routesEnum';
import { Logger } from 'fsts';
import store from '@/shared/store';
import AuthorityUtils from '@/shared/backend/authorityUtils';
import { AUTHORITIES } from '@/shared/store/modules/auth';
import { i18nGlobal } from '@/i18n';

const logger = new Logger('router');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: ROUTES.home,
    component: () => import('@/components/home/home.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.home_page') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
        AUTHORITIES.ATTENDEE,
      ],
    },
  },
  {
    path: `/${ROUTES.login}`,
    name: ROUTES.login,
    component: () => import('@/components/authorization/authorization.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.login') },
      requiresAuth: false
    },
  },
  {
    path: '/locations',
    name: ROUTES.locations,
    component: () => import('@/components/locations/locations.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.locations') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: '/locations/:locationId',
    name: ROUTES.location,
    component: () =>
      import('@/components/locations/locationAddEdit/locationAddEdit.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.location_edit') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: `/${ROUTES.employees}`,
    name: ROUTES.employees,
    component: () => import('@/components/employees/employees.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.employees') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
      ],
    },
  },
  {
    path: `/${ROUTES.employees}/:employeeId`,
    name: ROUTES.employee,
    component: () =>
      import('@/components/employees/employeeAddEdit/employeeAddEdit.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.employee_edit') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE
      ],
    },
  },
  {
    path: `/${ROUTES.trainers}`,
    name: ROUTES.trainers,
    component: () => import('@/components/trainers/trainers.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.trainers') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: `/${ROUTES.trainers}/:trainerId`,
    name: ROUTES.trainer,
    component: () =>
      import('@/components/trainers/trainerAddEdit/trainerAddEdit.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.trainer_edit') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: '/courses',
    name: ROUTES.courses,
    component: () => import('@/components/courses/courses.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.courses') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
        AUTHORITIES.ATTENDEE,
      ],
    },
  },
  {
    path: '/courses/:courseId/:appointmentId?',
    name: ROUTES.course,
    component: () =>
      import('@/components/courses/courseAddEdit/courseAddEdit.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.course_edit') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: '/courses/appointment/:appointmentId',
    name: ROUTES.event_appointment,
    component: () =>
      import(
        '@/components/courses/appointments/eventAppointmentAddEdit/eventAppointmentAddEdit.vue'
      ),
    meta: {
      get title() { return i18nGlobal.t('router.event_edit') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
      ],
    },
  },
  {
    path: `/${ROUTES.courses_coursetimes}`,
    name: ROUTES.courses_coursetimes,
    component: () => import('@/components/courses/dateTimes/dateTimes.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.course_times') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
      ],
    },
  },
  {
    path: `/${ROUTES.courses_appointments}`,
    name: ROUTES.courses_appointments,
    component: () =>
      import('@/components/courses/appointments/appointments.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.appointments') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
        AUTHORITIES.ATTENDEE,
      ],
    },
  },
  {
    path: `/${ROUTES.attendances}`,
    name: ROUTES.attendances,
    component: () => import('@/components/attendances/attendances.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.attendances') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.ATTENDEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: `/${ROUTES.attendances_presences}`,
    name: ROUTES.attendances_presences,
    component: () => import('@/components/attendances/presences/presences.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.presences') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
        AUTHORITIES.ATTENDEE,
      ],
    },
  },
  {
    path: `/${ROUTES.invoices}`,
    name: ROUTES.invoices,
    component: () => import('@/components/invoices/invoices.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.invoices') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
      ],
    },
  },
  {
    path: `/${ROUTES.invoice_items}`,
    name: ROUTES.invoice_items,
    component: () =>
      import('@/components/invoices/invoiceItems/invoiceItems.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.invoice_items') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
      ],
    },
  },
  {
    path: `/${ROUTES.attendees}`,
    name: ROUTES.attendees,
    component: () => import('@/components/attendees/attendees.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.attendees') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: `/${ROUTES.attendee_presences}`,
    name: ROUTES.attendee_presences,
    component: () =>
      import('@/components/attendees/attendeePresences/attendeePresences.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.presences') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: `/${ROUTES.attendees_interested}`,
    name: ROUTES.attendees_interested,
    component: () => import('@/components/attendees/interested/interested.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.interested') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: `/${ROUTES.attendees}/:attendeeId`,
    name: ROUTES.attendee,
    component: () =>
      import('@/components/attendees/attendeeAddEdit/attendeeAddEdit.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.attendee_edit') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: `/${ROUTES.attendees_interested}/:attendeeId`,
    name: ROUTES.attendee_interested,
    component: () =>
      import(
        '@/components/attendees/interested/interestedAddEdit/interestedAddEdit.vue'
      ),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.interested_edit') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: `/${ROUTES.my_profile}`,
    name: ROUTES.my_profile,
    component: () => import('@/components/myProfile/myProfile.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.my_profile') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.MANAGER,
        AUTHORITIES.TRAINER,
        AUTHORITIES.ATTENDEE,
      ],
    },
  },
  {
    path: `/${ROUTES.my_membership}`,
    name: ROUTES.my_membership,
    component: () => import('@/components/myMembership/myMembership.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.my_membership') },
      requiresAuth: true,
      requiresRole: [AUTHORITIES.ATTENDEE],
    },
  },
  {
    path: `/${ROUTES.my_invoices}`,
    name: ROUTES.my_invoices,
    component: () => import('@/components/myInvoices/myInvoices.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.my_invoices') },
      requiresAuth: true,
      requiresRole: [AUTHORITIES.ATTENDEE],
    },
  },
  {
    path: `/${ROUTES.my_documents}`,
    name: ROUTES.my_documents,
    component: () => import('@/components/myDocuments/myDocuments.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.my_documents') },
      requiresAuth: true,
      requiresRole: [AUTHORITIES.ATTENDEE],
    },
  },
  {
    path: `/${ROUTES.my_company}`,
    name: ROUTES.my_company,
    component: () => import('@/components/myCompany/myCompany.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.my_company') },
      requiresAuth: true,
      requiresRole: [AUTHORITIES.ADMIN, AUTHORITIES.MANAGER],
    },
  },
  {
    path: `/${ROUTES.reference_tables}`,
    name: ROUTES.reference_tables,
    component: () => import('@/components/references/references.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.reference_tables') },
      requiresAuth: true,
      requiresRole: [AUTHORITIES.ADMIN, AUTHORITIES.MANAGER],
    },
  },
  {
    path: `/${ROUTES.settings}`,
    name: ROUTES.settings,
    component: () => import('@/components/settings/settings.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.settings') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
      ],
    },
  },
  {
    path: `/${ROUTES.company_subscriptions}`,
    name: ROUTES.company_subscriptions,
    component: () => import('@/components/companySubscriptions/companySubscriptions.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.company_subscriptions') },
      requiresAuth: true,
      requiresRole: [AUTHORITIES.ADMIN, AUTHORITIES.MANAGER],
    },
  },
  {
    path: `/${ROUTES.checkIn_scanner}`,
    name: ROUTES.checkIn_scanner,
    component: () => import('@/components/checkInScanner/checkInScanner.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.checkin_scanner') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
      ],
    },
  },
  {
    path: `/${ROUTES.display_qr_code}`,
    name: ROUTES.display_qr_code,
    component: () => import('@/components/qrCodes/displayPersonalQrCode/displayPersonalQrCode.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.display_qr_code') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
        AUTHORITIES.ATTENDEE,
      ],
    },
  },
  {
    path: `/${ROUTES.print_qr_code}/:refId/:name`,
    name: ROUTES.print_qr_code,
    component: () => import('@/components/qrCodes/printQrCode/printQrCode.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.print_qr_code') },
      requiresAuth: false,
    },
  },
  {
    path: `/${ROUTES.print_attendance_list}/:appointmentId`,
    name: ROUTES.print_attendance_list,
    component: () => import('@/components/calendar/editDialog/attendances/print/print.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.print_attendances') },
      requiresAuth: false,
    },
  },
  {
    path: `/${ROUTES.conflicts}`,
    name: ROUTES.conflicts,
    component: () => import('@/components/conflicts/conflicts.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.conflicts') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
      ],
    },
  },
  {
    path: `/${ROUTES.tasks}`,
    name: ROUTES.tasks,
    component: () => import('@/components/taskRequests/taskRequests.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.todos') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
      ],
    },
  },
  {
    path: `/${ROUTES.public_view}/:companyId`,
    name: ROUTES.public_view,
    component: () => import('@/components/publicView/publicView.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.calendar') },
      requiresAuth: false,
    },
  },
  {
    path: `/${ROUTES.membership_application}`,
    name: `${ROUTES.membership_application}`,
    component: () =>
      import('@/components/membershipApplication/membershipApplication.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.membership_application') },
      requiresAuth: false,
    },
  },
  {
    path: `/${ROUTES.membership_application_confirmation}`,
    name: `${ROUTES.membership_application_confirmation}`,
    component: () =>
      import(
        '@/components/membershipApplication/applicationConfirmation/applicationConfirmation.vue'
      ),
    meta: {
      get title() { return i18nGlobal.t('router.application_confirmation') },
      requiresAuth: false,
    },
  },
  {
    path: `/${ROUTES.attendee_registration}`,
    name: `${ROUTES.attendee_registration}`,
    component: () =>
      import(
        '@/components/attendeeRegisterByQrCode/attendeeRegisterByQrCode.vue'
      ),
    meta: {
      get title() { return i18nGlobal.t('router.register_by_qr_code') },
      requiresAuth: false,
    },
  },
  {
    path: `/${ROUTES.attendee_registration_to_appointment}`,
    name: `${ROUTES.attendee_registration_to_appointment}`,
    component: () =>
      import(
        '@/components/attendeeRegisterByQrCode/interestedAttendanceRequest/interestedAttendanceRequest.vue'
      ),
    meta: {
      get title() { return i18nGlobal.t('router.attendance_request') },
      requiresAuth: false,
    },
  },
  {
    path: `/${ROUTES.self_checkin}`,
    name: ROUTES.self_checkin,
    component: () =>
      import(
        '@/components/attendeeRegisterByQrCode/memberSelfCheckin/memberSelfCheckin.vue'
      ),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.self_checkin') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
        AUTHORITIES.EMPLOYEE,
        AUTHORITIES.TRAINER,
        AUTHORITIES.ATTENDEE,
      ],
    },
  },
  {
    path: `/${ROUTES.statistics}`,
    name: ROUTES.statistics,
    component: () => import('@/components/statistics/statistics.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.statistics') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
      ],
    },
  },
  {
    path: `/${ROUTES.create_company}`,
    name: ROUTES.create_company,
    component: () => import('@/components/createCompany/createCompany.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.create_company') },
      requiresAuth: false,
    },
  },
  {
    path: `/${ROUTES.new_password}/:tokenId/user/:userId`,
    name: ROUTES.new_password,
    component: () => import('@/components//newPassword/newPassword.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.set_new_password') },
      requiresAuth: false,
    },
  },
  {
    path: `/${ROUTES.error_view}/:errorCode/:companyId`,
    name: `${ROUTES.error_view}`,
    component: () => import('@/components/errorView/errorView.vue'),
    meta: {
      get title() { return i18nGlobal.t('router.error_page') },
      requiresAuth: false,
    },
  },
  {
    path: `/${ROUTES.direct_debit_creation}`,
    name: ROUTES.direct_debit_creation,
    component: () => import('@/components/directDebitCreation/directDebitCreation.vue'),
    props: true,
    meta: {
      get title() { return i18nGlobal.t('router.sepa_direct_debit_creation') },
      requiresAuth: true,
      requiresRole: [
        AUTHORITIES.ADMIN,
        AUTHORITIES.MANAGER,
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to: any, from, next) => {
  document.title = `${to.meta.title}`;
  // always load api information
  await AuthorityUtils.getApiInfo();
  if (to.path == ROUTES.login) {
    next();
    return;
  }

  if (
    to.name == ROUTES.print_qr_code ||
    to.name == ROUTES.print_attendance_list
  ) {
    store.commit("set_isKioskMode", true);
  }

  // does the route require authorization?
  if (!to.matched.some((record: any) => record.meta.requiresAuth)) {
    next();
    return;
  }
  try {
    // enforce loading of account details
    await AuthorityUtils.ensureAccountDetails();

    //user is loggedin
    if (!AuthorityUtils.isEmptyAccount()) {
      logger.debug('isLoggedin');
      // check access to route based on roles
      if (
        !AuthorityUtils.isAdmin() &&
        !AuthorityUtils.hasAnyRole(to.meta.requiresRole)
      ) {
        logger.debug('!isAdmin && !hasAnyRole');
        next('error-access-view');
        return;
      }

      logger.debug(`from:${from.path},to:${to.path}`);
      next(); // all is fine
      return;
    }
  } catch (e) {
    logger.debug(e);
  }

  next({
    path: `/${ROUTES.login}`,
    query: { redirect: encodeURIComponent(to.fullPath) },
  });
});

export default router;
