import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { Filters, Value } from '../model/calendar';
import { i18nGlobal } from '@/i18n';
import { TypeOfPeriod1 as TypeOfPeriod } from '../model/periodTypes';
import { CONST } from '../utils/constants';
import ODataFilterBuilder from 'odata-filter-builder';
import { DefaultBackendHelper } from '../utils/backendHelper';
import { getCourseTypeText } from '../model/course';
import { isEmptyId } from '../utils/generalUtils';
import { Calendar } from '../model/calendar';

const logger = new Logger('backend.Appointment');
export interface BackendCalendar {
  getCalendar(
    payload: {
      date: string;
      dateType: TypeOfPeriod;
      companyId?: string;
      appointmentId?: string;
      attendeeId?: string;
    },
    filters: Filters
  ): AxiosPromise<Calendar[]>;
}

export const defaultBackendCalendar: BackendCalendar = {
  getCalendar(
    payload: {
      date: string;
      dateType: TypeOfPeriod;
      companyId?: string;
      appointmentId?: string;
      attendeeId?: string;
    },
    filters: Filters
  ): AxiosPromise<Calendar[]> {
    const companyId = payload?.companyId ?? CONST.emptyGuid;

    let baseUrl = `${URLS.calendar}/${i18nGlobal.locale.value}/${payload.date}/${payload.dateType}`;
    if (companyId !== CONST.emptyGuid) {
      baseUrl = `${URLS.calendar}/GetAnonymous/${companyId}/${i18nGlobal.locale.value}/${payload.date}/${payload.dateType}`;
    }

    let odfb = ODataFilterBuilder('and');
    odfb.eq('Course/Active', true);
    
    if (!isEmptyId(payload.appointmentId)) {
      odfb.eq(CONST.Id, payload.appointmentId, false);
    }

    if (!isEmptyId(payload.attendeeId)) {
      odfb.and(`Attendances/any(Attendance: Attendance/AttendeeId eq ${payload.attendeeId})`);
    }

    let odfbCourses = ODataFilterBuilder('or');
    filters.courses.forEach((id) => odfbCourses.eq(CONST.CourseId, id, false));

    let odfbRooms = ODataFilterBuilder('or');
    filters.rooms.forEach((id) => odfbRooms.eq(CONST.RoomId, id, false));

    let odfbLocations = ODataFilterBuilder('or');
    filters.locations.forEach((id) =>
      odfbLocations.eq('Course/LocationId', id, false)
    );

    let odfbTrainers = ODataFilterBuilder('or');
    filters.trainers.forEach((id) =>
      odfbTrainers.or(
        `AppointmentTrainers/any(AppointmentTrainer: AppointmentTrainer/TrainerId eq ${id})`
      )
    );

    let odfbDepartments = ODataFilterBuilder('or');
    filters.departments.forEach((id) =>
      odfbDepartments.or(
        `Course/DepartmentRefs/any(DepartmentRef: DepartmentRef/DepartmentId eq ${id})`
      )
    );

    let odfbCourseTypes = ODataFilterBuilder('or');
    filters.courseTypes.forEach((courseType) =>
      odfbCourseTypes.eq('Course/Type', getCourseTypeText(courseType))
    );

    odfb
      .and(odfbCourses)
      .and(odfbRooms)
      .and(odfbLocations)
      .and(odfbTrainers)
      .and(odfbDepartments)
      .and(odfbCourseTypes);

    const url = DefaultBackendHelper.makeUrl(
      baseUrl,
      undefined,
      undefined,
      undefined,
      odfb
    );
    return instance.get<Calendar[]>(url);
  },
};
