import DateUtils from '../utils/dateUtils';
import { TypeOfPeriod2 as TypeOfPeriod } from './periodTypes';

export interface AttendeeCourse {
  id?: string; // System.Guid
  attendeeId?: string; // System.Guid
  courseId?: string; // System.Guid
  dateTimeId?: string; // System.Guid
  period: TypeOfPeriod;
  periodValues: any;
  courseMax: number;
  courseText: string;
  courseName: string;
  start: string;
  end: string;
  timeFrom: string;
  timeTill: string;
  companyId?: string; // System.Guid
  creatorId?: string; // System.Guid?
  createdDate?: string; // System.DateTime
  creatorFullName?: string;
  cancelled: boolean;
  cancelledById?: string; // System.Guid
  cancelledDate?: string; // System.DateTime
  cancelledByFullName?: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  birthDay?: string;
}

export interface Value {
  value: AttendeeCourse[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<AttendeeCourse>): any {
  return {
    Id: data?.id || undefined,
    AttendeeId: data?.attendeeId || undefined,
    DateTimeId: data?.dateTimeId || undefined,
    CompanyId: data?.companyId || undefined,
    CreatorId: data?.creatorId || '',
    CreatedDate: data?.createdDate || undefined,
  };
}

// init api object
function arrayToAPI(data: Partial<AttendeeCourse[]>): any {
  return data.map((item?: AttendeeCourse) => {
    return {
      Id: item?.id || undefined,
      AttendeeId: item?.attendeeId || undefined,
      DateTimeId: item?.dateTimeId || undefined,
    };
  });
}

function parse(data?: Partial<AttendeeCourse>): AttendeeCourse {
  let period = data?.period || 'Day';
  return {
    id: data?.id || undefined,
    attendeeId: data?.attendeeId || undefined,
    courseId: data?.courseId || undefined,
    dateTimeId: data?.dateTimeId || undefined,
    period: TypeOfPeriod[period as keyof typeof TypeOfPeriod],
    periodValues: data?.periodValues?.split(',').map(Number) || [],
    courseMax: data?.courseMax || 0,
    courseName: data?.courseName || '',
    courseText: data?.courseText || '',
    start: data?.start || new Date().toISOString().substring(0, 10),
    end: DateUtils.parseDateFromBackend(data?.end),
    timeFrom: data?.timeFrom?.substring(0, 5) || '',
    timeTill: data?.timeTill?.substring(0, 5) || '',
    companyId: data?.companyId || undefined,
    creatorId: data?.creatorId || '',
    createdDate: data?.createdDate || '',
    creatorFullName: data?.creatorFullName || '',
    cancelledById: data?.cancelledById || '',
    cancelled: data?.cancelled ?? false,
    cancelledDate: data?.cancelledDate || '',
    cancelledByFullName: data?.cancelledByFullName || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    phone: data?.phone || '',
    email: data?.email || '',
    birthDay: data?.birthDay || undefined,
  };
}

export default {
  parse,
  toAPI: toAPI,
  arrayToAPI,
};
