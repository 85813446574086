import {
  Component,
  Vue,
  Watch,
  toNative,
  Setup,
} from 'vue-facing-decorator';
import { namespace } from 'vuex-facing-decorator';
import { useI18n } from 'vue-i18n';
import TooltipText from '@/components/_common/tooltipText/tooltipText.vue';
import { CredentialContact } from '@/shared/model/credentials';
import { CONST } from '@/shared/utils/constants';

const name = 'own-attendee-select';
const authModule = namespace('auth');
const locales = require('./locales.json');

@Component({ name: name, components: { TooltipText } })
class OwnAttendeeSelect extends Vue {
  @Setup((props, ctx) => useI18n({ messages: locales }))
  private i18n!: any;

  @authModule.Getter('getContacts')
  private contacts!: CredentialContact[];
  @authModule.Getter('getSelectedContact')
  private selectedContact!: CredentialContact|undefined;
  @authModule.Mutation('setSelectedContactIndex')
  private mutationSetSelectedContactIndex!: (index: number) => void;

  private selectboxEntryChanged(newId: string) {
    const newIndex = this.contacts.findIndex(x => x.attendeeId == newId)
    this.mutationSetSelectedContactIndex(newIndex);
  }

  private get selectboxEntries() {
    return this.contacts.map(x => ({
      id: x.attendeeId,
      name: `${x.firstName} ${x.lastName}`
    }));
  }

  private get selectedContactId() {
    return this.selectedContact?.attendeeId ?? CONST.emptyGuid;
  }

  private get mobile() {
    return this.$vuetify.display.mobile;
  }
}
export default toNative(OwnAttendeeSelect);