import { CONST } from "../utils/constants";
import DateUtils from "../utils/dateUtils";
import { WeekDay } from "./periodTypes";

export interface LocationOpeningHours {
  id: string;
  locationId: string;
  fromWeekDay: WeekDay;
  tillWeekDay?: WeekDay;
  closed: boolean;
  fromTime?: string;
  tillTime?: string;
  createdDate: string;
  createdById?: string;
  companyId: string;
}

function toAPI(data: Partial<LocationOpeningHours>): any {
  return {
    Id: data?.id || CONST.emptyGuid,
    LocationId: data?.locationId || CONST.emptyGuid,
    FromWeekDay: data?.fromWeekDay ?? WeekDay.Monday,
    TillWeekDay: data?.tillWeekDay ?? undefined,
    Closed: data?.closed ?? false,
    FromTime: data?.fromTime || undefined,
    TillTime: data?.tillTime || undefined,
    CreatedDate: data?.createdDate || DateUtils.getCurrentLocalDateAsIsoString(),
    CreatedById: data?.createdById || undefined,
    CompanyId: data?.companyId || CONST.emptyGuid,
  };
}

function parse(data?: Partial<LocationOpeningHours>): LocationOpeningHours {
  return {
    id: data?.id || CONST.emptyGuid,
    locationId: data?.locationId || CONST.emptyGuid,
    fromWeekDay: data?.fromWeekDay ?? WeekDay.Monday,
    tillWeekDay: data?.tillWeekDay ?? undefined,
    closed: data?.closed ?? false,
    fromTime: data?.fromTime?.substring(0,5) || undefined,
    tillTime: data?.tillTime?.substring(0,5) || undefined,
    createdDate: data?.createdDate || DateUtils.getCurrentLocalDateAsIsoString(),
    createdById: data?.createdById || undefined,
    companyId: data?.companyId || CONST.emptyGuid,
  };
}

export default {
  parse,
  toAPI: toAPI,
};
