import { MutationTree } from 'vuex';
import { AttendeeCourseWaitingPositionState } from './types';
import attendeeCourseWaitingPositionFuncs, { Value } from '@/shared/model/attendeeCourseWaitingPosition';

export const mutations: MutationTree<AttendeeCourseWaitingPositionState> = {
  setAttendeeCourseWaitingPositions(state, payload: Value) {
    state.attendeeCourseWaitingPositions.items = payload.value.map((x) =>
      attendeeCourseWaitingPositionFuncs.parse(x)
    );
    state.attendeeCourseWaitingPositions.total = payload['@odata.count'] || 0;
    state.attendeeCourseWaitingPositions.isLoading = false;
  },
  setAttendeeCourseWaitingPositionsTotal(state, payload: number) {
    state.attendeeCourseWaitingPositions.total = payload;
  },
  setAttendeeCourseWaitingPositionsIsLoading(state, payload: boolean) {
    state.attendeeCourseWaitingPositions.isLoading = payload;
  },
  setAttendeeCourseWaitingPositionPage(state, payload: number) {
    state.attendeeCourseWaitingPositions.searchParams.dataOption.page = payload;
  },
};
