import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import locationOpeningHourFuncs, { LocationOpeningHours } from '../model/locationOpeningHours';

export interface BackendLocationOpeningHours {
  getOpeningHoursByLocation(
    locationId: string
  ): AxiosPromise<LocationOpeningHours[]>;
  postMultipleOpeningHours(
    locationId: string,
    openingHours: LocationOpeningHours[]
  ): AxiosPromise<any>;
}

export const defaultBackendLocationOpeningHours: BackendLocationOpeningHours = {
  getOpeningHoursByLocation(
    locationId: string
  ): AxiosPromise<LocationOpeningHours[]> {
    const url = `${URLS.locationOpeningHours}/GetByLocation/${locationId}`;
    return instance.get<LocationOpeningHours[]>(url)
  },
  postMultipleOpeningHours(
    locationId: string,
    openingHours: LocationOpeningHours[]
  ): AxiosPromise<any> {
    const url = `${URLS.locationOpeningHours}/AddMultiple`;
    return instance.post(
      url,
      {
        LocationId: locationId,
        OpeningHours: openingHours.map(locationOpeningHourFuncs.toAPI)
      }
    );
  }
}