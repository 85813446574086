import { createStore, ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from './types';
import { Snackbar } from '../model/snackbar';
import { instance } from '../backend';
import DateUtils from '../utils/dateUtils';
import moment from 'moment';
import createPersistedState from 'vuex-persistedstate';
import { i18nGlobal } from '@/i18n';

import { auth } from './modules/auth';
import { settings } from './modules/settings';
import { documentModule } from './modules/documentModule';
import { locationModule } from './modules/locationModule';
import { webPushModule } from './modules/webPushModule';
import { departmentModule } from './modules/departmentModule';
import { presenceModule } from './modules/presenceModule';
import { appointmentModule } from './modules/appointmentModule';
import { membershipRequestModule } from './modules/membershipRequestModule';
import { taskRequestModule } from './modules/taskRequestModule';
import { attendanceModule } from './modules/attendanceModule';
import { newsModule } from './modules/newsModule';
import { newsRefModule } from './modules/newsRefModule';
import { checkInModule } from './modules/checkInModule';
import { referenceParentModule } from './modules/referenceParentModule';
import { attendeeModule } from './modules/attendeeModule';
import { trainerModule } from './modules/trainerModule';
import { employeeModule } from './modules/employeeModule';
import { noteModule } from './modules/noteModule';
import { invoicePositionModule } from './modules/invoicePositionModule';
import { invoiceModule } from './modules/invoiceModule';
import { presenceAttendanceModule } from './modules/presenceAttendanceModule';
import { roomModule } from './modules/roomModule';
import { roomPresenceModule } from './modules/roomPresenceModule';
import { qrCodeModule } from './modules/qrCodeModule';
import { appointmentMessageModule } from './modules/appointmentMessageModule';
import { calendarModule } from './modules/calendarModule';
import { courseTrainerModule } from './modules/courseTrainerModule';
import { appointmentTrainerModule } from './modules/appointmentTrainerModule';
import { departmentRefModule } from './modules/departmentRefModule';
import { attendeeCourseModule } from './modules/attendeeCourseModule';
import { courseModule } from './modules/courseModule';
import { reportModule } from './modules/reportModule';
import { skillModule } from './modules/skillModule';
import { locationEmployeeModule } from './modules/locationEmployeeModule';
import { roomAvailabilityModule } from './modules/roomAvailabilityModule';
import { availabilityModule } from './modules/availabilityModule';
import { referenceModule } from './modules/referenceModule';
import { tariffModule } from './modules/tariffModule';
import { tariffRefModule } from './modules/tariffRefModule';
import { dateTimeModule } from './modules/dateTimeModule';
import { bankDataModule } from './modules/bankDataModule';
import { contactModule } from './modules/contactModule';
import { locationAttendeeModule } from './modules/locationAttendeeModule';
import { membershipTypeCostOverrideModule } from './modules/membershipTypeCostOverrideModule';
import { countryModule } from './modules/countryModule';
import { companyModule } from './modules/companyModule';
import { costDiscountModule } from './modules/costDiscountModule';
import { membershipApplicationModule } from './modules/membershipApplicationModule';
import { attendeeRegisterByQrCodeModule } from './modules/attendeeRegisterByQrCodeModule';
import { stripeModule } from './modules/stripeModule';
import { departmentResponsibleModule } from './modules/departmentResponsibleModule';
import { locationResponsibleModule } from './modules/locationResponsibleModule';
import { statisticsModule } from './modules/statisticsModule';
import { directDebitModule } from './modules/directDebitModule';
import { applicationUserPermissionsModule } from './modules/applicationUserPermissionsModule';
import { attendeeCourseWaitingPositionsModule } from './modules/attendeeCourseWaitingPositionModule';
import { locationOpeningHoursModule } from './modules/locationOpeningHoursModule';

export const actions: ActionTree<State, RootState> = {
  async hideSnackbar({ commit }) {
    commit('setSnackbarVisibility', false);
  },
  setRowsPerPage({ commit }, rows: number) {
    commit('setRowsPerPage', rows);
  },
  /**
   * Load backend API version information.
   * @param commit
   */
  ['load_info']: ({ commit }) => {
    instance.get<any>('/api/users/info').then((value) => {
      if (value && value.data) {
        commit('set_info', value.data);
      }
    });
  },
  ['set_isKioskMode']: ({ commit }, payload: boolean) => {
    commit('set_isKioskMode', payload);
  },
  ['off_autologoutTimeEnabled']: ({ commit }) => {
    commit('set_autologoutTimeEnabled', false);
  },
};

export const mutations: MutationTree<State> = {
  showError: (s: State, payload: any) => {
    s.snackbarlog.push(
      '  error:' + DateUtils.formatDateTimeISO(new Date()) + payload
    );
    s.snackbar.show = true;
    s.snackbar.color = 'error';
    s.snackbar.text = payload;
    s.snackbar.duration = 5000;
  },
  showSuccess: (s: State, payload: any) => {
    s.snackbarlog.push(
      'success:' + DateUtils.formatDateTimeISO(new Date()) + payload
    );
    s.snackbar.show = true;
    s.snackbar.color = 'success';
    s.snackbar.text = payload;
    s.snackbar.duration = 5000;
  },
  setSnackbarError: (s: State, payload: any) => {
    s.snackbarlog.push(
      '  error:' + DateUtils.formatDateTimeISO(new Date()) + payload
    );
    s.snackbar.show = true;
    s.snackbar.color = 'error';
    s.snackbar.text = payload.message;
    s.snackbar.duration = payload.duration;
  },
  setSnackbarSuccess: (s: State, payload: any) => {
    s.snackbarlog.push(
      'success:' + DateUtils.formatDateTimeISO(new Date()) + payload
    );
    s.snackbar.show = true;
    s.snackbar.color = 'success';
    s.snackbar.text = payload.message;
    s.snackbar.duration = payload.duration;
  },
  setSnackbarVisibility: (s: State, visibility: boolean) => {
    s.snackbar.show = visibility;
  },
  setRowsPerPage: (s: State, rows: number) => {
    s.pagination.rowsPerPage = rows;
  },
  ['set_info']: (
    s: State,
    info: { version: any; commit: any; currentUtcDate: any }
  ) => {
    s.apiInfo = { version: info.version, commit: info.commit };
    s.currentUtcDate = info.currentUtcDate;
  },
  ['set_dateFormat']: (s: State, dateFormat: any) => {
    s.dateFormat = dateFormat;
  },
  ['set_isKioskMode']: (s: State, isKioskMode: boolean) => {
    s.isKioskMode = isKioskMode;
  },
  ['set_autologoutTimeEnabled']: (s: State, autologoutTimeEnabled: boolean) => {
    s.autologoutTimeEnabled = autologoutTimeEnabled;
  },
};

export const getters: GetterTree<State, RootState> = {
  ['info']: (s: State) => s.apiInfo,
  ['currentUtcDate']: (s: State) => s.currentUtcDate,
  ['currentLocalDate']: (s: State) => {
    let currentUtcDate = moment(s.currentUtcDate);
    let timezoneOffset = moment().utcOffset();
    return moment(currentUtcDate).add(timezoneOffset, 'minutes');
  },

  ['frontend_info']: (s: State) => process.env.VERSION || 0,
  ['dateFormat']: (s: State) => s.dateFormat,
  ['isKioskMode']: (s: State) => s.isKioskMode,
  ['autologoutTimeEnabled']: (s: State) => s.autologoutTimeEnabled,
  ['dateOutputFormat']: (s: State) =>
    i18nGlobal.locale.value == 'de' ? 'DD.MM.YYYY' : 'YYYY/MM/DD',
};

export interface State {
  apiInfo: any;
  currentUtcDate: any;
  pagination: any;
  dateFormat: string;
  snackbar: Snackbar;
  isKioskMode: boolean;
  snackbarlog: string[];
  autologoutTimeEnabled: boolean;
}

export const state = (): State => ({
  apiInfo: null,
  currentUtcDate: '',
  snackbar: {
    show: false,
    color: '',
    text: '',
    duration: 5000,
  },
  snackbarlog: [],
  pagination: {
    rowsPerPage: 25,
  },
  isKioskMode: false,
  dateFormat: 'DD.MM.YYYY',
  autologoutTimeEnabled: true,
});

const store = createStore({
  state: state() as any,
  getters,
  mutations,
  actions,
  modules: {
    auth,
    settings,
    locationModule,
    documentModule,
    webPushModule,
    departmentModule,
    presenceModule,
    appointmentModule,
    membershipRequestModule,
    taskRequestModule,
    attendanceModule,
    newsModule,
    newsRefModule,
    checkInModule,
    referenceParentModule,
    attendeeModule,
    trainerModule,
    employeeModule,
    noteModule,
    invoicePositionModule,
    invoiceModule,
    presenceAttendanceModule,
    roomModule,
    roomPresenceModule,
    qrCodeModule,
    appointmentMessageModule,
    calendarModule,
    courseTrainerModule,
    appointmentTrainerModule,
    departmentRefModule,
    attendeeCourseModule,
    courseModule,
    reportModule,
    skillModule,
    locationEmployeeModule,
    roomAvailabilityModule,
    availabilityModule,
    referenceModule,
    tariffModule,
    tariffRefModule,
    dateTimeModule,
    bankDataModule,
    contactModule,
    locationAttendeeModule,
    membershipTypeCostOverrideModule,
    countryModule,
    companyModule,
    costDiscountModule,
    membershipApplicationModule,
    attendeeRegisterByQrCodeModule,
    stripeModule,
    departmentResponsibleModule,
    locationResponsibleModule,
    statisticsModule,
    directDebitModule,
    applicationUserPermissionsModule,
    attendeeCourseWaitingPositionsModule,
    locationOpeningHoursModule,
  },
  plugins: [
    createPersistedState({
      key: 'attmag-org',
      paths: [
        'appointmentModule.selectedFiltersForCalendar.locations',
        'appointmentModule.selectedFiltersForCalendar.departments',
        'locationModule.selectedOwnUserLocationIndex',
        'departmentModule.selectedOwnUserDepartmentIndex',
      ],
    }),
  ],
});

export default store;
