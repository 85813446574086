import DateUtils from '../utils/dateUtils';
import { i18nGlobal } from '@/i18n';
import { CONST } from '../utils/constants';
import { TypeOfPeriod2 as TypeOfPeriod } from './periodTypes';

export interface DateTime {
  id?: string; // System.Guid
  name: string;
  day: number; // int
  timeFrom: string; // System.TimeSpan
  timeTill: string; // System.TimeSpan
  time: string; // timeFrom - timeTill
  timeFromString?: string;
  timeTillString?: string;
  dateFrom: string; // System.DateTime
  dateTill: string; // System.DateTime
  maxAttendees: number;
  roomId?: string;
  courseId?: string;
  tariffId?: string; // System.Guid?
  periodValues: any;
  eachOnDay: number;
  companyId?: string;
  createdDate: string;
  period: TypeOfPeriod;
  trainerFullNames: string;
  courseName: string;
  locationId: string; //System.Guid
  courseActive: boolean;
  bookableAsSeries: boolean;
  countOfSeriesCourseAttendees: number;
}

// export type PeriodTypeEnum = 1 | 2 | 3;
// export const PeriodTypeEnum = {
//   Day: 1 as PeriodTypeEnum,
//   Week: 2 as PeriodTypeEnum,
//   Month: 3 as PeriodTypeEnum,
// };

export interface Value {
  value: DateTime[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<DateTime>): any {
  return {
    Id: data?.id || undefined,
    Name: data?.name || '',
    Day: data?.day || 0,
    TimeFrom: data?.timeFrom || '',
    TimeTill: data?.timeTill || '',
    DateFrom: data?.dateFrom || new Date().toISOString(),
    DateTill: DateUtils.dateNullableToApi(data?.dateTill),
    MaxAttendees: data?.maxAttendees || 0,
    RoomId: data?.roomId || undefined,
    CourseId: data?.courseId || undefined,
    TariffId: data?.tariffId || undefined,
    EachOnDay: data?.eachOnDay || 1,
    PeriodValues: data?.periodValues.join(',') || undefined,
    CompanyId: data?.companyId || undefined,
    Period: data?.period || TypeOfPeriod.Day,
  };
}

function parse(data?: Partial<DateTime>): DateTime {
  let period = TypeOfPeriod.Day;
  if (typeof data?.period == 'string') {
    period = TypeOfPeriod[data.period as keyof typeof TypeOfPeriod];
  } else if (data?.period) {
    period = data.period;
  }

  return {
    id: data?.id || undefined,
    name: data?.name || '',
    day: data?.day || 0,
    timeFrom:
      data?.timeFromString?.substring(0, 5) || data?.timeFrom || '09:00',
    timeTill:
      data?.timeTillString?.substring(0, 5) || data?.timeTill || '12:00',
    get time() {
      return `${this.timeFrom} - ${this.timeTill}`;
    },
    dateFrom: data?.dateFrom || new Date().toISOString().substring(0, 10),
    dateTill: DateUtils.parseDateFromBackend(data?.dateTill),
    maxAttendees: data?.maxAttendees || 0,
    roomId: data?.roomId || undefined,
    eachOnDay: data?.eachOnDay || 1,
    courseId: data?.courseId || undefined,
    tariffId: data?.tariffId || undefined,
    companyId: data?.companyId || undefined,
    periodValues:
      typeof data?.periodValues == 'string'
        ? data?.periodValues?.split(',').map(Number)
        : data?.periodValues || [],
    createdDate: data?.createdDate || new Date().toISOString().substring(0, 10),
    period: period,
    trainerFullNames: data?.trainerFullNames || '',
    courseName: data?.courseName || '',
    locationId: data?.locationId || CONST.emptyGuid,
    courseActive: data?.courseActive ?? false,
    bookableAsSeries: data?.bookableAsSeries ?? false,
    countOfSeriesCourseAttendees: data?.countOfSeriesCourseAttendees || 0,
  };
}

function getPeriodString(item: DateTime): string {
  let periodTranslated =
    item.period == TypeOfPeriod.Day
      ? i18nGlobal.t('app.period_day')
      : item.period == TypeOfPeriod.Week
      ? i18nGlobal.t('app.period_week')
      : i18nGlobal.t('app.period_month');
  if (item.periodValues.length == 0 || item.period == TypeOfPeriod.Day) {
    return periodTranslated;
  }
  let periodValuesTranslated = '';

  if (item.period == TypeOfPeriod.Month) {
    periodValuesTranslated += item.eachOnDay;
  }
  if (item.period == TypeOfPeriod.Week) {
    item.periodValues.forEach((value: number) => {
      periodValuesTranslated +=
        i18nGlobal.t(`app.day_of_week_short[${value}]`) + ',';
    });
    // remove last ','
    periodValuesTranslated = periodValuesTranslated.slice(0, -1);
  }

  let result = `${periodValuesTranslated} (${periodTranslated})`;
  return result;
}

export default {
  parse,
  toAPI: toAPI,
  getPeriodString,
};
