import { MutationTree } from 'vuex';
import axios from 'axios';
import { AuthState } from './types';
import { initialState } from './state';
import { AUTHORITIES } from './index';
import { Credentials } from '../../../model/credentials';
import LSService from '../../../backend/localStorageService';
import referenceParent, {
  ReferenceParent,
} from '@/shared/model/referenceParent';
import applicationUserPermissions, { ApplicationUserPermissions } from '@/shared/model/applicationUserPermissions';

const lsService = LSService.getService();

export const mutations: MutationTree<AuthState> = {
  /**
   * Set the authentication token.
   */
  ['token']: (state: AuthState, token: string) => {
    state.token = token;
    if (token) {
      localStorage.setItem('accessToken', token);
      axios.defaults.headers.common.Authorization = token;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  },
  setIsFacebookLogin(state: AuthState, payload: boolean) {
    state.isFacebookLogin = payload;
  },
  setIsGoogleLogin(state: AuthState, payload: boolean) {
    state.isGoogleLogin = payload;
  },
  setAccountId(state: AuthState, id: string) {
    state.error = false;
    state.accountId = id;
  },
  setCompanyId(state: AuthState, id: string) {
    state.error = false;
    state.companyId = id;
  },
  setAccount(state: AuthState, payload: Credentials | undefined) {
    state.error = false;
    state.account = payload;
    state.account?.contacts.forEach(
      (x) => (x.membershipTypes = x.membershipTypes?.map(referenceParent.parse))
    );
    if (state.account) {
      state.inactivityMinutes = state.account.inactivityMinutes;
      state.timeSheetFormat = state.account.timeSheetFormat;
    }
    if (state.account && state.account.authorities) {
      state.isAdmin = state.account.authorities.includes(AUTHORITIES.ADMIN);
      state.isManager = state.account.authorities.includes(AUTHORITIES.MANAGER);
      state.isEmployee = state.account.authorities.includes(
        AUTHORITIES.EMPLOYEE
      );
      state.isTrainer = state.account.authorities.includes(AUTHORITIES.TRAINER);
      state.isAttendee = state.account.authorities.includes(
        AUTHORITIES.ATTENDEE
      );
    }
  },
  setPermissions(state: AuthState, payload: ApplicationUserPermissions) {
    state.permissions = applicationUserPermissions.parse(payload);
  },
  setSelectedContactIndex(state: AuthState, payload: number) {
    state.account!.selectedContactIndex = payload;
  },
  setToken(state: AuthState, payload: any) {
    lsService.setToken(payload);
  },
  clearTokens(state: AuthState, payload: any) {
    lsService.clearToken();
  },
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key: string) => {
      state[key] = s[key];
    });
  },
  setSubscriptionInfo(state: AuthState, payload: any) {
    if (payload == null) return;
    state.totalActiveUsersInDb = payload.totalActiveUsersInDb;
    state.totalLicences = payload.totalLicences;
    state.trialBeginDate = payload.trialBeginDate;

    if (state.trialBeginDate != undefined) {
      let trialEndDate = new Date(state.trialBeginDate);
      trialEndDate.setTime(trialEndDate.getTime() + (14 * 1000 * 60 * 60 * 24));
      let currentDate = new Date();
      let res = Math.ceil((trialEndDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));

      state.trialDaysLeft = res >= 0 ? res : 0;
    } else {
      state.trialDaysLeft = -1;
    }
  },
  setIsResponsibleOrDeputy(state, payload: boolean) {
    state.isResponsibleOrDeputy = payload;
  },
};
