import { ActionTree } from 'vuex';
import { AttendeeState } from './types';
import { RootState } from '../../types';
import { defaultBackendAttendee } from '@/shared/backend/attendee';
import { Logger } from 'fsts';
import moduleUtils from '../moduleUtils';
import attendee, { Value, Attendee } from '@/shared/model/attendee';
import { AxiosResponse } from 'axios';
import { i18nGlobal } from '@/i18n';
import { ApplicationUser, Value as ApplicationUserValue } from '@/shared/model/applicationUser';
import { isEmptyId } from '@/shared/utils/generalUtils';
import { createSearchParams } from '@/shared/model/searchParams';
import DownloadUtils from '@/shared/utils/downloadUtils';

const logger = new Logger('actions.attendees');
export const actions: ActionTree<AttendeeState, RootState> = {
  getAttendees(
    { commit, dispatch, getters, rootGetters },
    payload?: {
      searchParams?: any;
      locationId?: string;
      departmentId?: string;
      noStore?: boolean;
      active?: boolean;
      excludeAttendeeIds?: string[];
      attendeeIds?: string[];
      filterByMembershipTypes?: string[];
      trainerId?: string;
      departmentIds?: string[];
      attendeeRoleIds?: string[];
    }
  ) {
    commit("setAttendeesIsLoading", true);
    const searchParams =
      payload?.searchParams ?? getters.getAttendeesSearchParams;
    return defaultBackendAttendee
      .getAttendees(
        searchParams,
        payload?.locationId,
        payload?.departmentId,
        payload?.active,
        payload?.excludeAttendeeIds,
        payload?.attendeeIds,
        payload?.filterByMembershipTypes,
        payload?.departmentIds,
        payload?.trainerId,
        payload?.attendeeRoleIds,
      )
      .then((response: AxiosResponse<Value>) => {
        commit("setAttendeesIsLoading", false);
        if (payload?.noStore) return response.data;

        commit("setAttendees", response.data);
        return response.data;
      })
      .catch((e: any) => {
        commit("setAttendeesIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  getInterested(
    { commit, dispatch, getters, rootGetters },
    payload?: { searchParams?: any; locationId?: string; departmentId?: string }
  ) {
    commit("setInterestedIsLoading", true);
    let searchParams =
      payload?.searchParams ?? getters.getInterestedSearchParams;
    return defaultBackendAttendee
      .getInterested(searchParams, payload?.locationId, payload?.departmentId)
      .then((response: AxiosResponse<Value>) => {
        commit("setInterested", response.data);
        commit("setInterestedIsLoading", false);
        return response.data;
      })
      .catch((e: any) => {
        commit("setInterestedIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  getApplicationUsers({ commit, getters }, payload?: { searchParams?: any; } ) {
    let searchParams = payload?.searchParams ?? getters.getApplicationUsersSearchParams;
    return defaultBackendAttendee
      .getDistinctAppUsers(searchParams)
      .then((response: AxiosResponse<ApplicationUserValue>) => {
        commit("setApplicationUsers", response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  getAttendeeWaitingList(
    { commit, getters },
    payload?: { searchParams?: any; appointmentId?: string }
  ) {
    commit("setWaitingListIsLoading", true);
    const appointmentId = payload?.appointmentId ?? "";
    if (isEmptyId(appointmentId)) {
      return;
    }
    const searchParams =
      payload?.searchParams ?? getters.getInterestedSearchParams;
    return defaultBackendAttendee
      .getAttendeeWaitingList(searchParams, appointmentId)
      .then((response: AxiosResponse<Value>) => {
        commit("setWaitingList", response.data);
        commit("setWaitingListIsLoading", false);
        return response.data;
      })
      .catch((e: any) => {
        commit("setWaitingListIsLoading", false);
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  getAttendeesByAppointmentId(
    { commit, getters },
    payload: {
      searchParams?: any;
      appointmentId: string;
      noStore?: string;
    }
  ) {
    commit("setAttendeesIsLoading", true);
    let searchParams =
      payload?.searchParams ?? getters.getAttendeesSearchParams;
    return defaultBackendAttendee
      .getAttendeesByAppointmentId(searchParams, payload.appointmentId)
      .then((response: AxiosResponse<Value>) => {
        if (payload?.noStore) return response.data;
        commit("setAttendees", response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit("setAttendeesIsLoading", false);
      });
  },

  getAttendeesWithoutMembership(
    { commit, getters },
    payload: {
      searchParams?: any;
      locationId?: string;
      departmentId?: string;
      noStore?: boolean;
    }
  ) {
    commit("setAttendeesIsLoading", true);
    let searchParams =
      payload?.searchParams ?? getters.getAttendeesSearchParams;
    return defaultBackendAttendee
      .getAttendeesWithoutMembership(
        searchParams,
        payload?.locationId,
        payload?.departmentId
      )
      .then((response: AxiosResponse<Value>) => {
        if (payload?.noStore) return response.data;
        commit("setAttendees", response.data);
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      })
      .finally(() => {
        commit("setAttendeesIsLoading", false);
      });
  },

  getAttendeesWithoutMembershipTotalOpenCount(
    { dispatch, commit },
    payload: { locationId?: string; departmentId?: string }
  ) {
    dispatch("getAttendeesWithoutMembership", {
      locationId: payload?.locationId,
      departmentId: payload?.departmentId,
      searchParams: createSearchParams(0),
      noStore: true,
    }).then((resp: Value) => {
      commit("setAttendeesWithoutMembershipTotalOpenCount", resp);
      return resp["@odata.count"];
    });
  },

  getAttendee({ commit, dispatch }, id: string) {
    return defaultBackendAttendee
      .getAttendeeByApplicationUserId(id)
      .then((response: AxiosResponse<Attendee>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  getAttendeeByAttendeeId({ commit }, attendeeId: string) {
    return defaultBackendAttendee
      .getAttendeeByAttendeeId(attendeeId)
      .then((response: AxiosResponse<Attendee>) => {
        return attendee.parse(response.data);
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  requestNewMemberNumber({ commit }) {
    return defaultBackendAttendee
      .requestNewMemberNumber()
      .then((response: AxiosResponse<string>) => {
        return response.data;
      })
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },

  updateAttendee(
    { commit },
    payload: { attendee: Attendee; sendMembershipTerminatedMessages?: boolean }
  ) {
    return defaultBackendAttendee
      .updateAttendee(
        payload.attendee,
        payload.sendMembershipTerminatedMessages
      )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.attendee_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  updateInterestedAttendee({ commit, dispatch }, attendee: Attendee) {
    return defaultBackendAttendee
      .updateInterestedAttendee(attendee)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.attendee_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  registerAttendee(
    { commit, dispatch },
    payload: { newAttendee: ApplicationUser; locationId: string }
  ) {
    return defaultBackendAttendee
      .registerAttendee(payload.newAttendee, payload.locationId)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.register_attendee`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  registerInterestedAttendeeByEmployee(
    { commit, dispatch },
    payload: { newAttendee: Attendee; locationId: string }
  ) {
    return defaultBackendAttendee
      .registerInterestedAttendeeByEmployee(
        payload.newAttendee,
        payload.locationId
      )
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          moduleUtils.ok(i18nGlobal.t(`success.register_attendee`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  deleteAttendee({ commit, dispatch }, id: string) {
    return defaultBackendAttendee
      .deleteAttendee(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  deleteInterestedAttendee({ commit, dispatch }, id: string) {
    return defaultBackendAttendee
      .deleteInterestedAttendee(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Attendee;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        moduleUtils.error("error", commit, e, logger);
        throw e;
      });
  },
  async exportAttendees(
    { commit, getters },
    payload: {
      searchParams?: any;
      locationId?: string;
      departmentId?: string;
      filterByMembershipTypes?: string[];
      departmentIds?: string[];
    }
  ) {
    commit("setAttendeesIsLoading", true);
    const culture = i18nGlobal.locale.value == "de" ? "de-DE" : "en-US";
    const searchParams = payload.searchParams ?? getters.getAttendeesSearchParams;

    try {
      await defaultBackendAttendee
        .exportAttendees(
          searchParams,
          culture,
          payload.locationId,
          payload.departmentId,
          payload.filterByMembershipTypes,
          payload.departmentIds,
        ).then((response) => {
          const fileName = DownloadUtils.getFileName(
            response.headers["content-disposition"]
          );
          const type = response.headers["content-type"];
          if (DownloadUtils.isIeOrEdge(window)) {
            const file = new File([response.data], fileName, {
              type: type,
            });
            (window.navigator as any).msSaveOrOpenBlob(file, fileName);
          } else {
            const blob = new Blob([response.data], { type: type });
            const url = DownloadUtils.createDownloadLink(blob, fileName);
            window.URL.revokeObjectURL(url);
          }
        });
    } catch (e) {
      logger.error(e);
      moduleUtils.error(i18nGlobal.t(`error.download_file`), commit, e, logger);
      throw e;
    } finally {
      commit("setAttendeesIsLoading", false);
    }
  },
};
