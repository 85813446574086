import { AttendeeCourseState } from './types';

export const state = (): AttendeeCourseState => initialState();

export const initialState = (): AttendeeCourseState => ({
  attendeeCourses: {
    items: [],
    isLoading: false,
    total: 0,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['createdDate'],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: true,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['firstName', 'lastName', 'email'],
    },
  },
});
