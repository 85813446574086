import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/dist/vuetify.min.css';
import i18n from '../i18n';
import { createVuetify } from 'vuetify';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { useI18n } from 'vue-i18n';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

export const defaultColor = '#5F78A5';
const defaultColors = {
  primary: defaultColor,
  secondary: '#3342AB',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  footer: '#fc735c',
  'deep-blue-lunacy--bg': '#5F78A5',
  'button-primary': '#EACD14',
  darkorange: '#ff9800',
};

const vuetify = createVuetify({
  components: components,
  directives: directives,
  icons: {
    defaultSet: 'mdi', // 'md' || 'fa' || 'mdi'|| 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    themes: {
      // Copied over from the vue.js 2 - attmag
      /* LINKS with colors, where one can get name and info
      1) https://www.colorbook.io/hexcolors/view/59adff
      2) ! https://encycolorpedia.com/59adff (!! I USE THIS LINK)
      3) https://www.htmlcsscolor.com/hex/59ADFF
      */
      light: {
        dark: false,
        colors: { ...defaultColors },
      },
      dark: {
        dark: true,
        colors: { ...defaultColors },
      }
    },
  },
});

export default vuetify;
